// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  child,
  ref as dbRef,
  get,
  getDatabase,
  remove,
  set,
  update,
} from "firebase/database";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadString,
} from "firebase/storage";

import { v4 as uuid } from "uuid";
import Category from "../util/Category";
import SubCategory from "../util/SubCategory";
import ClothingItemModel from "../models/ClothingItemModel";
import ResidencyInfoModel from "../models/ResidencyInfoModel";

// The web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMfLlAzd_-I9groRLa7_SVA3ZI4UVh6hQ",
  authDomain: "interactive-virtual-wardrobe.firebaseapp.com",
  databaseURL:
    "https://interactive-virtual-wardrobe-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "interactive-virtual-wardrobe",
  storageBucket: "interactive-virtual-wardrobe.appspot.com",
  messagingSenderId: "24212295127",
  appId: "1:24212295127:web:816bc706fecf854024cf92",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const databaseRef = dbRef(database);
const storage = getStorage(app);

// constants for main paths
const CLOTHING_MAPPINGS = "clothing_mappings";
const RESIDENCIES = "residencies";
const OUTFITS = "outfits";

// constants for subpaths
const CURRENT_CLOTHES = "current_clothes";
const MISSING_CLOTHES = "missing_clothes";
const INFO = "info";

// Weather API key
// Note: TRIAL Ends on 31/May/2023
// From: https://www.weatherapi.com/my/
const weather_api_key = "37bcf742b395481fb69113247231705";
const weather_api_base = "https://api.weatherapi.com/v1";

/**
 * Adds an outfit under a specific residency
 * @param {string} residencyId - the id of the residency
 * @param {ClothingItemModel} hat - the hat of the outfit
 * @param {ClothingItemModel} upper - the upper wear item of the outfit
 * @param {ClothingItemModel} lower - the lower wear item of the outfit
 * @param {ClothingItemModel} shoes - the shoes of the outfit
 * @returns the id of the added outfit
 */
export async function addOutfit(residencyId, hat, upper, lower, shoes) {
  const outfitId = uuid().slice(0, 8);
  set(dbRef(database, `${OUTFITS}/${residencyId}/${outfitId}/`), {
    hat: hat,
    upper: upper,
    lower: lower,
    shoes: shoes,
  });
  return outfitId;
}

/**
 * Edits an outfit under a specific residency
 * @param {string} residencyId - the id of the residency
 * @param {ClothingItemModel} hat - the hat of the outfit
 * @param {ClothingItemModel} upper - the upper wear item of the outfit
 * @param {ClothingItemModel} lower - the lower wear item of the outfit
 * @param {ClothingItemModel} shoes - the shoes of the outfit
 * @param {*} outfitKey - the id of the outfit
 * @returns the id of the outfit
 */
export async function editOutfit(
  residencyId,
  hat,
  upper,
  lower,
  shoes,
  outfitKey
) {
  const outfitRef = dbRef(database, `${OUTFITS}/${residencyId}/${outfitKey}/`);
  await set(outfitRef, {
    hat: hat,
    upper: upper,
    lower: lower,
    shoes: shoes,
  });
  return outfitKey;
}

/**
 * Deletes an outfit under a specifc residency
 * @param {string} residencyId - the id of the residency
 * @param {string} outfitKey - the id of the outfit
 */
export async function deleteOutfit(residencyId, outfitKey) {
  const outfitRef = dbRef(database, `${OUTFITS}/${residencyId}/${outfitKey}`);
  await remove(outfitRef);
}

/**
 * @returns all the outfits
 */
export async function getOutfits() {
  let outfits = (await get(child(databaseRef, `${OUTFITS}/`))).val();
  if (outfits) {
    const allResidencies = Object.keys(outfits);
    outfits = allResidencies.map((residency) => outfits[residency]);
    // Loop over each outfit and add the id
    outfits = outfits.map((outfits) => {
      return Object.entries(outfits).map(([id, data]) => ({
        id,
        ...data,
      }));
    });
    // Put the array of arrays of outfits into one array
    outfits = outfits.flat();
    return outfits;
  }
  return [];
}

/**
 * Get all the outfits from a specific residency
 * @param {string} residencyId - the id of the residency
 * @returns all the outfits from a residency
 */
export async function getOutfitsFromResidency(residencyId) {
  const path = `${OUTFITS}/${residencyId}/`;
  const outfits = (await get(child(databaseRef, path))).val();
  if (outfits) {
    const outfitKeys = Object.keys(outfits);
    return outfitKeys.map((key) => {
      return { ...outfits[key], key };
    });
  }
  return outfits;
}

/**
 * Get all the clothes by residency
 * @param {string} residencyId - the id of the residency
 * @param {string} clothesType - the specific type of clothes we want, there are two types, either the 'missing' ones or
 *                               the 'current' (available) ones.
 * @returns all the clothes from a residency
 */
async function getClothesByResidency(residencyId, clothesType) {

  const currentClothesRfids = (
    await get(
      child(
        databaseRef,
        `${RESIDENCIES}/${residencyId}/${clothesType}_clothes/`
      )
    )
  ).val();
  if (currentClothesRfids) {
    const clothingMappings = (
      await get(child(databaseRef, `${CLOTHING_MAPPINGS}/`))
    ).val();
    if (clothingMappings) {
      const clothes = Object.entries(clothingMappings).map(([id, data]) => ({
        ...data,
        id,
      }));
      const filteredClothes = clothes.filter((item) =>
        currentClothesRfids.includes(item.id)
      );
      const promises = filteredClothes.map(async (data) => ({
        rfid: data.id,
        category: data.category,
        subCategory: data.subCategory,
        imagePath: await getDownloadURL(ref(storage, data.imagePath)),
        imageId: data.imagePath,
        tag: data.tag,
      }));

      return Promise.all(promises);
    }
  }

  return [];
}

/**
 * Get all the clothes from all residencies
 * @returns {Object[]} Array of clothing objects
 */
export async function getEverything() {
  const residencies = await getResidencies();
  let clothes = [];

  const p = residencies.map(async (r) => {
    let id = r.id;
    clothes.push(await getAllClothes(id));
  });

  await Promise.all(p);

  return clothes.flat();
}

/**
 * Get all the available clothes by residency
 * @param {string} residencyId - the id of the residency
 * @returns all the available clothes from a resideny
 */
export async function getAllClothes(residencyId) {
  return getClothesByResidency(residencyId, "current");
}

/**
 * Get all the missing clothes by residency
 * @param {string} residencyId - the id of the residency
 * @returns all the missing clothes from a resideny
 */
export async function getAllMissingClothes(residencyId) {
  return getClothesByResidency(residencyId, "missing");
}

/**
 * Get all residencies
 * @returns all the residencies
 */
export async function getResidencies() {
  const dataObj = (await get(child(databaseRef, `${RESIDENCIES}`))).val();
  return Object.keys(dataObj).map((key) => {
    const { info } = dataObj[key];
    return { ...info, id: key };
  });
}

/**
 *
 * @param {AddResidencyModel} residency - contains all the neccesary information for adding a residency
 * @returns the id of the added residency
 */
export async function addResidency(residency) {
  const residencyId = uuid().slice(0, 8);
  set(dbRef(database, `${RESIDENCIES}/${residencyId}/${INFO}/`), {
    tag: residency.tag,
    color: residency.color,
    address: residency.address,
  });
  return residencyId;
}

/**
 * Update the info of a residency
 * @param {ResidencyInfoModel} residency - the residency we want to edit
 */
export async function updateResidency(residency) {
  const id = residency.id;
  const data = residency.data;
  const updates = {};
  updates[`${RESIDENCIES}/${id}/${INFO}/`] = data;
  return update(dbRef(database), updates);
}

/**
 * Adds an RFID to the current_clothings list of a residency. This is essential so that the user can view the clothing piece in his wardrobe
 * or on the 'manage outfits' page.
 * @param {string} residencyId - the id of the residency
 * @param {string} rfid - the RFID code of the clothing item
 */
export async function addClothingToResidency(residencyId, rfid) {

  // Get the current RFIDs
  const current = (
    await get(
      child(databaseRef, `${RESIDENCIES}/${residencyId}/${CURRENT_CLOTHES}/`)
    )
  ).val();

  // If there are no current clothes, create an array
  if (!current) {
    const newArr = [rfid];
    await set(
      dbRef(database, `${RESIDENCIES}/${residencyId}/${CURRENT_CLOTHES}`),
      newArr
    );
    return;
  }

  // Otherwise add the RFID to the array
  current?.push(rfid);

  await set(
    dbRef(database, `${RESIDENCIES}/${residencyId}/${CURRENT_CLOTHES}`),
    current
  );
}

/**
 * Delete a residency
 * @param {string} residencyId - the id of the residency
 */
export async function deleteResidency(residencyId) {
  remove(dbRef(database, `${RESIDENCIES}/${residencyId}`));
}

/**
 * Set the user id in LocalStorage
 * @param {string} id - the id of the user
 */
export function setUserId(id) {
  localStorage.setItem("userId", JSON.stringify(id));
}

/**
 * Get the user id from LocalStorage
 * @returns the user id
 */
export function getUserId() {
  return JSON.parse(localStorage.getItem("userId"));
}

/**
 * Add clothing item by residency
 * @param {ClothingItemModel} clothing - the clothing item to add
 * @param {ResidencyInfoModel} residency - the residency to add
 */
export async function addClothing(clothing, residency) {
  let { img, rfid, cat, subcat } = clothing;

  cat = cat.toLowerCase();
  subcat = subcat.toLowerCase();

  const storageRef = ref(storage, `${cat}/${uuid().slice(0, 8)}`);
  const imagePath = (await uploadString(storageRef, img, "data_url")).ref
    .fullPath;
  const clothingLocation = {
    category: cat,
    subCategory: subcat,
    imagePath: imagePath,
  };

  // Add the clothing to the realtime db
  addClothingToResidency(residency.id, rfid);

  set(dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`), clothingLocation);
}

/**
 * Delete a clothing item from a residency and delete it from Firebase Storage also.
 * @param {string} residencyId - the id of the residency
 * @param {string} rfid - the RFID code
 * @param {string} imageId - the id of the image
 */
export async function deleteClothing(residencyId, rfid, imageId) {
  // Delete from clothing mappings
  remove(dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`));

  // First gets all the RFIDs, then gets the idx of the rfid to delete.
  const idx = (
    await get(
      child(databaseRef, `${RESIDENCIES}/${residencyId}/${CURRENT_CLOTHES}/`)
    )
  )
    .val()
    .indexOf(rfid);

  if (idx !== -1) {
    // Remove from db
    remove(
      dbRef(database, `${RESIDENCIES}/${residencyId}/${CURRENT_CLOTHES}/${idx}`)
    );
  }

  // Also remove the clothing from all missing items if it's there
  const dataObj = (await get(child(databaseRef, `${RESIDENCIES}`))).val();

  Object.keys(dataObj).forEach(async (key) => {
    const missingClothes = (
      await get(child(databaseRef, `${RESIDENCIES}/${key}/${MISSING_CLOTHES}/`))
    ).val();
    if (missingClothes) {
      const idx = missingClothes.indexOf(rfid);
      if (idx !== -1) {
        await remove(
          dbRef(database, `${RESIDENCIES}/${key}/${MISSING_CLOTHES}/${idx}`)
        );
      }
    }
  });

  const storageRef = ref(storage, `${imageId}`);
  await deleteObject(storageRef);
}

/**
 * Update the RFID from a clothing item
 * @param {string} rfid - the old RFID code
 * @param {string} newRfid - the new RFID code
 */
export async function editRfidClothing(rfid, newRfid) {
  const clothing_mapping = (
    await get(child(databaseRef, `${CLOTHING_MAPPINGS}/${rfid}`))
  ).val();
  remove(dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`));
  set(dbRef(database, `${CLOTHING_MAPPINGS}/${newRfid}`), clothing_mapping);
}

/**
 * Get all the clothes from a clothing category
 * @param {string} category - the clothing category
 * @returns all the clothes from a category
 */
export async function getCategory(category) {
  const storageRef = ref(storage, `${category}`);
  const result = await listAll(storageRef);

  const res = result.items.map(async (firebaseObject) => {
    let meta = await getMetadata(firebaseObject);
    return {
      img: await getDownloadURL(firebaseObject),
      id: meta.name,
      category: meta.fullPath.split("/")[1],
    };
  });
  return Promise.all(res);
}

/**
 * Get the currently selected residency in Local Storage
 * @returns the current selected residency from Local Storage
 */
export function getLocalCurrentResidency() {
  return JSON.parse(localStorage.getItem("currentResidency"));
}

/**
 * Set the currently selected residency in Local Storage
 * @param {ResidencyInfoModel} residency - the current selected residency
 */
export function setLocalCurrentResidency(residency) {
  localStorage.setItem("currentResidency", JSON.stringify(residency));
}

// Source: https://wunnle.com/dynamic-text-color-based-on-background
/**
 * Converts a hexadecimal code to a RGB code
 * @param {string} c - the hexadecimal code
 * @returns the converted RBG code
 */
export function getRGB(c) {
  return parseInt(c, 16) || c;
}

export function getsRGB(c) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

export function getLuminance(hexColor) {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
}

export function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor) {
  const whiteContrast = getContrast(bgColor, "#ffffff");
  const blackContrast = getContrast(bgColor, "#000000");

  return whiteContrast > blackContrast ? "#ffffff" : "#000000";
}

/**
 * Edit the tag of a clothing item
 * @param {string} rfid - the RFID code
 * @param {string} newTag - the new tag to add to the clothing item
 * @returns
 */
export async function editTagClothing(rfid, newTag) {
  const clothing_mapping = (
    await get(child(databaseRef, `${CLOTHING_MAPPINGS}/${rfid}`))
  ).val();
  clothing_mapping.tag = newTag;
  const updates = {};
  updates[`${CLOTHING_MAPPINGS}/${rfid}`] = clothing_mapping;
  return update(dbRef(database), updates);
}

/**
 * Delete all the missing clothes from a residency
 * @param {string} residencyId - the id of the residency
 */
export async function deleteMissingItemsFromResidency(residencyId) {
  // Delete from clothing mappings
  remove(dbRef(database, `${RESIDENCIES}/${residencyId}/${MISSING_CLOTHES}/`));
}

/**
 * Get weather data based on your location
 * @param {string} lat - latitude
 * @param {string} lon - longitude
 * @returns the weather data
 */
export async function getWeatherData(lat, lon) {
  const url = `${weather_api_base}/current.json?key=${weather_api_key}&q=${lat},${lon}`;
  const res = await fetch(url);
  const data = await res.json();
  return data;
}

/**
 * Delete a missing clothing item from a residency
 * @param {string} residencyId - the id of the residency
 * @param {string} rfid - the RFID code
 */
export async function deleteMissingItemFromResidency(residencyId, rfid) {
  // First gets all the RFIDs, then gets the idx of the rfid to delete.
  const idx = (
    await get(
      child(databaseRef, `${RESIDENCIES}/${residencyId}/${MISSING_CLOTHES}/`)
    )
  )
    .val()
    .indexOf(rfid);

  // Delete from clothing mappings
  remove(
    dbRef(database, `${RESIDENCIES}/${residencyId}/${MISSING_CLOTHES}/${idx}`)
  );
}

/**
 * Add clothing item to missing clothes of residency
 * @param {string} residencyId - the id of the residency
 * @param {string} rfid - the RFID code
 */
async function addClothingToResidencyMissing(residencyId, rfid) {
  // Get the current RFIDs
  const current = (
    await get(
      child(databaseRef, `${RESIDENCIES}/${residencyId}/${MISSING_CLOTHES}/`)
    )
  ).val();

  // If there are no current clothes, create an array
  if (!current) {
    const newArr = [rfid];
    await set(
      dbRef(database, `${RESIDENCIES}/${residencyId}/${MISSING_CLOTHES}`),
      newArr
    );
    return;
  }

  // Otherwise add the RFID to the array
  current?.push(rfid);

  await set(
    dbRef(database, `${RESIDENCIES}/${residencyId}/${MISSING_CLOTHES}`),
    current
  );
}

/**
 * This function is used to upload and save a lot of clothes in Firebase.
 */
export async function uploadMockData() {
  const residencies = [{ id: "185d7e40" }, { id: "c73ae7ed" }];

  // Remove existing data
  await remove(dbRef(database, `${OUTFITS}`));
  await remove(dbRef(database, `${CLOTHING_MAPPINGS}`));

  await remove(
    dbRef(database, `${RESIDENCIES}/${"185d7e40"}/${MISSING_CLOTHES}/`)
  );
  await remove(
    dbRef(database, `${RESIDENCIES}/${"185d7e40"}/${CURRENT_CLOTHES}/`)
  );
  await remove(
    dbRef(database, `${RESIDENCIES}/${"c73ae7ed"}/${MISSING_CLOTHES}/`)
  );
  await remove(
    dbRef(database, `${RESIDENCIES}/${"c73ae7ed"}/${CURRENT_CLOTHES}/`)
  );

  let rfid_tags = [
    "e2806894000050137df22896",
    "e2806894000050137df22496",
    "e2806894000050137df0fc91",
    "30395dfa8351fa0253b5b00b",
    "3039606343af938000189f69",
    "e2806894000040137df23896",
    "e2806894000050137df23c96",
    "e2806894000050137df24896",
    "e2806894000040137df24096",
    "e2806894000050137df24496",
    "e2806894000050137df25096",
    "e2806894000040137df24c96",
  ];

  const img_hats = [
    "78a07855-5a8e-496f-a516-a69e3784bbf1.jpg",
    "ff62e59f-c949-4f8e-8fca-798ab3e174c5.jpg",
    "bcc1aec3-9913-4a2d-b6a0-8f4d97e58b0c.jpg",
    "bade5af9-c62c-4f10-9b52-c31f50211d48.jpg",
    "31ae3916-c352-45fe-9866-a8947fc74991.jpg",
    "3f0be386-9a59-4aeb-93f6-dbe63e3a3002.jpg",
    "6c2f18d0-30ff-468c-a470-39c65abf4851.jpg",
    "d116fe9a-a72b-49e2-806d-e92f3a67a032.jpg",
    "2a12baab-f020-42e3-8e6b-5d82e3ed0b55.jpg",
    "a762dfc0-8da7-46d4-9ef1-3c7a085142da.jpg",
    "7b5181d5-253f-4936-9207-60e3368bf9e1.jpg",
    "c399e385-258a-465c-95d1-ba216c16d7d1.jpg",
  ];

  const img_upper = [
    "4e0f02de-645d-4615-a13b-749d8027c85b.jpg",
    "01fd527c-da05-4141-861c-3aaec8070601.jpg",
    "0af4203f-8998-4719-92fe-65c14a6b6093.jpg",
    "0c024616-1289-4747-91da-71a4aedd4ced.jpg",
    "3a3a16c8-942d-4541-867b-e97d5a793ea0.jpg",
    "0e99edde-0ed8-4c8c-a405-0517c1b801a4.jpg",
    "01b3083f-4387-4753-8ae2-a2470dc53f19.jpg",
    "0d73e759-eeaa-43ab-98d4-196b6974c41e.jpg",
    "0a86d893-93f1-4a52-8c42-07bb9f439833.jpg",
    "1b93822d-09f4-41e6-b31b-964cc104ba71.jpg",
    "0df1efc7-34ba-4e11-b027-21ec8fa5a35e.jpg",
    "0f4d9494-612a-48f8-be05-e47f4fc54d54.jpg",
    "3f844e1e-4a00-4b64-8c1d-3b847191bf11.jpg",
    "4ceed2f1-8e20-4439-9c27-cceb8d2257a4.jpg",
    "IMG_4855.JPEG",
    "IMG_4856.JPEG",
  ];

  const img_lower = [
    "6aa97899-d731-4d8b-9a96-0db401c28500.jpg",
    "2e6303c5-e8ef-4bb3-9ebc-ac97f3ab68d0.jpg",
    "3bf0d767-b78d-44e4-8b25-740aeb870b06.jpg",
    "1e302085-eeb2-46ff-a4f6-e9bb5cfa44b1.jpg",
    "84b4da94-65c0-471e-a7ad-2ba6360f45bb.jpg",
    "95e66e02-b4ed-4fe9-b538-db6bac4fb7df.jpg",
    "32b99302-cec7-4dec-adfa-3d4029674209.jpg",
    "1b5f2882-e33e-4efc-b469-bcf87b9f53ed.jpg",
    "4aabd82c-82e1-4181-a84d-d0c6e550d26d.jpg",
    "0dfec862-c49f-430b-a6ef-c7ceb187225e.jpg",
    "IMG_4859.JPEG",
    "IMG_4858.JPEG",
  ];

  const img_shoes = [
    "3e35227b-2061-46eb-8379-a6844a4bd5ac.jpg",
    "2d986f3f-de01-498a-9657-68e28c84a6be.jpg",
    "1e170a9f-9437-4eae-bd67-389c48b93ff9.jpg",
    "2be8cf7a-5b2e-400f-a826-785997af532a.jpg",
    "2b54e651-5d43-4f24-9e9e-a58ce16d7c88.jpg",
    "0dd87e47-ca85-4d5c-9fd1-59f5a01eb656.jpg",
    "IMG_4865.JPEG",
    "IMG_4864.JPEG",
  ];
  let counter = 0;

  for (const img in img_hats) {
    // Choose random subcategory from the right category
    const subcat = Object.keys(SubCategory).filter(
      (k) => SubCategory[k] === Category.HATS
    );
    const randomSubCat =
      subcat[Math.floor(Math.random() * subcat.length)].toLocaleLowerCase();
    // assign a random residency
    const res = residencies[Math.floor(Math.random() * residencies.length)];
    const clothingLocation = {
      category: "hats",
      subCategory: randomSubCat,
      imagePath: `hats/${img_hats[img]}`,
      tag: "",
    };

    // Choose the rfid. 15% chance to use one of the rfid_tags array, and 85% chance
    // to use the counter variable as value for the rfid
    const chooseCounter = Math.random() < 0.85;
    const rfid = chooseCounter ? counter++ + "" : rfid_tags[0];
    // if one of the rfid_tags is chosen, it should be removed from the array
    if (!chooseCounter) {
      rfid_tags.splice(rfid_tags.indexOf(rfid), 1);
    }

    // 85:15 Chance on adding it to the current or missing clothes
    const putInCurrent = Math.random() < 0.85;
    if (putInCurrent) await addClothingToResidency(res.id, rfid + "");
    else await addClothingToResidencyMissing(res.id, rfid + "");
    await set(
      dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`),
      clothingLocation
    );
  }

  for (const img in img_upper) {
    // Choose random subcategory from the right category
    const subcat = Object.keys(SubCategory).filter(
      (k) => SubCategory[k] === Category.UPPER
    );
    const randomSubCat =
      subcat[Math.floor(Math.random() * subcat.length)].toLocaleLowerCase();
    // assign a random residency
    const res = residencies[Math.floor(Math.random() * residencies.length)];
    const clothingLocation = {
      category: "upper",
      subCategory: randomSubCat,
      imagePath: `upper/${img_upper[img]}`,
      tag: "",
    };

    const chooseCounter = Math.random() < 0.85;
    const rfid = chooseCounter ? counter++ + "" : rfid_tags[0];
    if (!chooseCounter) {
      rfid_tags.splice(rfid_tags.indexOf(rfid), 1);
    }

    const putInCurrent = Math.random() < 0.5;
    if (putInCurrent) await addClothingToResidency(res.id, rfid + "");
    else await addClothingToResidencyMissing(res.id, rfid + "");
    await set(
      dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`),
      clothingLocation
    );
  }

  for (const img in img_lower) {
    // Choose random subcategory from the right category
    const subcat = Object.keys(SubCategory).filter(
      (k) => SubCategory[k] === Category.LOWER
    );
    const randomSubCat =
      subcat[Math.floor(Math.random() * subcat.length)].toLocaleLowerCase();
    // assign a random residency
    const res = residencies[Math.floor(Math.random() * residencies.length)];
    const clothingLocation = {
      category: "lower",
      subCategory: randomSubCat,
      imagePath: `lower/${img_lower[img]}`,
      tag: "",
    };

    const chooseCounter = Math.random() < 0.85;
    const rfid = chooseCounter ? counter++ + "" : rfid_tags[0];
    if (!chooseCounter) {
      rfid_tags.splice(rfid_tags.indexOf(rfid), 1);
    }

    const putInCurrent = Math.random() < 0.5;
    if (putInCurrent) await addClothingToResidency(res.id, rfid + "");
    else await addClothingToResidencyMissing(res.id, rfid + "");
    await set(
      dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`),
      clothingLocation
    );
  }

  for (const img in img_shoes) {
    // Choose random subcategory from the right category
    const subcat = Object.keys(SubCategory).filter(
      (k) => SubCategory[k] === Category.SHOES
    );
    const randomSubCat =
      subcat[Math.floor(Math.random() * subcat.length)].toLocaleLowerCase();
    // assign a random residency
    const res = residencies[Math.floor(Math.random() * residencies.length)];
    const clothingLocation = {
      category: "shoes",
      subCategory: randomSubCat,
      imagePath: `shoes/${img_shoes[img]}`,
      tag: "",
    };

    // For the rfid, choose first the rfid_tags array if there is anything left in it
    // otherwise use counter
    const rfid = rfid_tags.length > 0 ? rfid_tags[0] : counter++ + "";
    if (rfid_tags.length > 0) {
      rfid_tags.splice(rfid_tags.indexOf(rfid), 1);
    }

    const putInCurrent = Math.random() < 0.5;
    if (putInCurrent) await addClothingToResidency(res.id, rfid + "");
    else await addClothingToResidencyMissing(res.id, rfid + "");
    await set(
      dbRef(database, `${CLOTHING_MAPPINGS}/${rfid}`),
      clothingLocation
    );
  }
}
