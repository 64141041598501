import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {
    uploadMockData
} from "../services/service";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Settings = () => {
    return (
        <Container>
            <Row className="justify-content-center">
                <h1>Settings</h1>
            </Row>
            <Row>
                <h2>Preferences</h2>
            </Row>
            <Row>
                <Col xs={{ offset: 3, span: 6 }} >
                    <Button onClick={uploadMockData}>Upload mock data</Button>
                </Col>
            </Row>
            <Row>
                <Form>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="generateShort"
                        label="Wear shorts in cold weather (< 20°C)"
                    />
                    <Form.Check // prettier-ignore
                        type="switch"
                        label="Always wear a hat"
                        id="generateHat"
                    />
                </Form>
            </Row>
        </Container>
    )
}

export default Settings;