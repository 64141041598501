
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Fragment } from 'react';

function NavBar() {
  // Different pages to put in the navbar 
  const pages = ['add-clothes', 'create-outfits', 'outfits', 'residencies', 'wardrobe', 'travel-wardrobe', 'shopping', 'settings']

  // Name shown in the navbar 
  const names = {
    'add-clothes': 'Add clothes',
    'outfits': 'My outfits',
    'create-outfits': 'Create outfits',
    residencies: 'Residencies',
    settings: 'Settings',
    shopping: 'Shopping',
    wardrobe: 'Wardrobe',
    'travel-wardrobe': 'Travel',
  }

  return (
    <Navbar style={{ marginBottom: '1%' }} bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/dashboard">IVWS</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" variant="tabs">
            {pages.map((page, idx) => (
              <Fragment key={page}>
                {/* Proper spacing between all n navigation items (except for last one) */}
                <Nav.Link href={page}>{names[page]}</Nav.Link>
                {idx !== pages.length - 1 && <Nav.Item>&nbsp;</Nav.Item>}
              </Fragment>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;