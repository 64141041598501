import { faBox, faCamera, faGear, faHouse, faShirt, faSuitcaseRolling, faCirclePlus, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DashBoardButton from '../components/DashboardButton';

const Dashboard = () => {
    return (
        <Container>
            <Row className="justify-content-center">
                <h1>Home page</h1>
            </Row>
            <Row>
                <DashBoardButton icon={faCamera} title="Add clothes" link="/add-clothes" />
                <DashBoardButton icon={faCirclePlus} title="Create outfits" link="/create-outfits" />
                <DashBoardButton icon={faShirt} title="My outfits" link="/outfits" />
                <DashBoardButton icon={faHouse} title="Residencies" link="/residencies" />
                <DashBoardButton icon={faBox} title="Wardrobe" link="/wardrobe" />
                <DashBoardButton icon={faSuitcaseRolling} title="Travel" link="/travel-wardrobe" />
                <DashBoardButton icon={faCartPlus} title="Shopping" link="/shopping" />
                <DashBoardButton icon={faGear} title="Settings" link="/settings" />
            </Row>
        </Container>
    )
}

export default Dashboard;