import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { getOutfits } from "../services/service";
import "./css/CreateOutfits.css";
import ArrowClick from "../util/ArrowClick.js";

const Outfits = () => {
  const [outfits, setOutfits] = useState([]);
  const [currentOutfit, setCurrentOutfit] = useState(0);

  // Fetch the outfits from Firebase
  useEffect(() => {
    getOutfits().then((outfits) => {
      setOutfits(outfits);
    });
  }, []);

  // Browse through the outfits 
  const arrowClick = (direction) => {
    const increment = direction === ArrowClick.LEFT ? -1 : 1;
    const newIndex = (currentOutfit + increment + outfits.length) % outfits.length;
    setCurrentOutfit(newIndex);
  }

  return (
    <Container>
      {
        outfits.length > 0 ?
          <Row className="items-center text-center">
            <Col xs={12}>
              <h1>Number of Outfits: {outfits.length}</h1>
            </Col>
            <Col>
              <Button variant="light" disabled={outfits.length < 2}>
                <i
                  className="text-4xl bi bi-arrow-left"
                  onClick={() => arrowClick(ArrowClick.LEFT)}
                />
              </Button>
            </Col>
            <Col className="mt-4 mb-4">
              <Row>
                <div>
                  <img
                    src={
                      outfits.length > 0
                        ? outfits[currentOutfit].hat.imagePath
                        : "https://via.placeholder.com/125x125"
                    }
                    alt="headwear"
                    className="rounded-lg resized-image"
                  />
                </div>
              </Row>
              <Row className="mt-4 mb-4">
                <div>
                  <img
                    src={
                      outfits.length > 0
                        ? outfits[currentOutfit].upper.imagePath
                        : "https://via.placeholder.com/125x125"
                    }
                    alt="headwear"
                    className="rounded-lg resized-image"
                  />
                </div>
              </Row>
              <Row className="mt-4 mb-4">
                <div>
                  <img
                    src={
                      outfits.length > 0
                        ? outfits[currentOutfit].lower.imagePath
                        : "https://via.placeholder.com/125x125"
                    }
                    alt="headwear"
                    className="rounded-lg resized-image"
                  />
                </div>
              </Row>
              <Row className="mt-4 mb-4">
                <div>
                  <img
                    src={
                      outfits.length > 0
                        ? outfits[currentOutfit].shoes.imagePath
                        : "https://via.placeholder.com/125x125"
                    }
                    alt="headwear"
                    className="rounded-lg resized-image"
                  />
                </div>
              </Row>
            </Col>
            <Col>
              <Button variant="light" disabled={outfits.length < 2}>
                <i
                  className="text-4xl bi bi-arrow-right"
                  onClick={() => arrowClick(ArrowClick.RIGHT)}
                />
              </Button>
            </Col>
            <Col xs={12}>
              <h2>Current Outfit: {currentOutfit + 1}</h2>
            </Col>
          </Row>
          :
          <>
            <h1>No outfits to be shown!</h1>
          </>
      }
    </Container>
  );
};

export default Outfits;
