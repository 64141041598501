import { getAverageColor, getMostSimilar } from "../util/AverageColor"

// Input images
import pant from "../test/testPants.JPEG"
import shirt from "../test/testShirt.png"
import skirtTest from "../test/testSkirt.jpg"
import shortTest from "../test/testshorts.jpg"
import skirtTest2 from "../test/testSkirt2.jpg"

// Masks 
//  Hats 
import hatMask from "../overlays/masks/hat_mask.png"

//  Upper
import dressMask from "../overlays/masks/dress_mask.png"
import longsleeveMask from "../overlays/masks/longsleeve_mask.png"
import outwearMask from "../overlays/masks/outwear_mask.png"
import shirtMask from "../overlays/masks/shirt_mask.png"
import tshirtMask from "../overlays/masks/shirt_mask.png"

//  Lower
import pantMask from "../overlays/masks/pants_mask.png"
import shortMask from "../overlays/masks/shorts_mask.png"
import skirtMask from "../overlays/masks/skirt_mask.png"

//  Shoes
import shoeMask from "../overlays/masks/shoes_mask.png"

import { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

// This file is used as debuggin tool for the suggestion system 
// You can play around with the different input images and overlays to test different scenarios

const Debug = () => {

    const [a, setA] = useState('');
    const [sim, setSim] = useState(null)
    const [cutout, setCutout] = useState('')

    // Input image
    const currentInput = skirtTest2
    // Input mask
    const currentMask = skirtMask

    getAverageColor(currentInput, currentMask).then(r => {
        let img = r[0] // Image 
        let rgb = r[1] // Average rgb 
        setA(img)

        // Get the most similar image
        getMostSimilar(rgb, currentMask).then(rr => {
            setSim(rr)
        })
    })

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = currentInput; // Replace with the URI or path of your main image

    const maskImage = new Image();
    maskImage.src = currentMask; // Replace with the URI or path of your mask image

    image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;

        // Generate Cutout image
        ctx.drawImage(image, 0, 0);
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(maskImage, 0, 0);

        // Now you can use the modified canvas as needed
        setCutout(canvas.toDataURL())
    };

    return (
        <div>
            <h1>Debug</h1>

            <Container>
                <Row>
                    <Col>
                        Input:
                        <img width={300} src={currentInput} alt='c' />
                    </Col>
                    {cutout ?
                        <Col>
                            Cutout:
                            <img width={300} src={cutout} alt='cutout' />
                        </Col>
                        :
                        <></>
                    }
                </Row>

                <Row className="my-3">
                    <Col>
                        Average RGB: {a}
                        <div style={{
                            width: '100px',
                            height: '100px',
                            backgroundColor: a
                        }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        Most similar:
                        <img src={sim} style={{ maxHeight: '200px' }} alt='mostsim' />
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Debug