import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { setUserId } from "../../services/service";

const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState("test@hotmail.com");
  const [password, setPassword] = useState("Azerty123");

  const goToRegister = () => {
    navigate("/Register")
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const userId = userCredential.user.uid;
        // Set the userId in the local storage 
        setUserId(userId)
        navigate('/dashboard')
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };
  return (
    <div className="flex h-5/6 items-center justify-center mx-auto">
      <form onSubmit={handleSubmit}>
        <div className="form-outline mb-4">
          <label className="form-label">Email address</label>
          <input type="email" id="form2Example1" className="form-control" value={email}
            onChange={handleEmailChange} />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label">Password</label>
          <input type="password" id="form2Example2" className="form-control" value={password}
            onChange={handlePasswordChange} />
        </div>

        <div className="row mb-4">
          <div className="col d-flex justify-content-center">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="form2Example31" />
              <label className="form-check-label"> Remember me </label>
            </div>
          </div>

          <div className="col">
            <a href="#!">Forgot password?</a>
          </div>
        </div>

        <button type="button" className="btn btn-primary btn-block mb-4 w-full" onClick={handleSubmit}>Sign in</button>

        <div className="text-center">
          <p>Not a member? <a className="text-primary" onClick={goToRegister}>Register</a></p>
        </div>
      </form>
    </div>
  );
};

export default Login;
