import Category from "../util/Category";

const SubCategory = Object.freeze({
    Hat:        Category.HATS,

    Dress:      Category.UPPER,
    Longsleeve: Category.UPPER,
    Outwear:    Category.UPPER,
    'T-shirt':  Category.UPPER,
    Shirt:      Category.UPPER,

    Pants:      Category.LOWER,
    Shorts:     Category.LOWER,
    Skirt:      Category.LOWER,
    
    Shoes:      Category.SHOES,
})

export default SubCategory;