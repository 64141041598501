import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function DashboardButton({ icon, title, link }) {
    const linkStyle = {
        marginBottom: '5%',
    }

    const buttonStyle = {
        width: '100%',
        height: '100%',
    }

    return (
        <Col style={linkStyle} xs={6} md={6} lg={4} xl={3}>
            <Link to={link}>
                <Button variant='outline-primary' style={buttonStyle} size='lg'>
                    <FontAwesomeIcon icon={icon} />
                    <div>{title}</div>
                </Button>
            </Link>
        </Col>
    )
}


export default DashboardButton;