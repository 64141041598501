import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faLocationDot,
  faTag,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Collapse, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import { BlockPicker } from "react-color";
import {
  addResidency,
  deleteResidency,
  getResidencies,
  updateResidency,
} from "../services/service";
import "./css/Residencies.css";

const Residencies = () => {
  const [residencies, setResidencies] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#F47373");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [editingResidencyId, setEditingResidencyId] = useState("");
  const [editingSelectedColor, setEditingSelectedColor] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [deleteResidencyId, setDeleteResidencyId] = useState("");

  // Fetch the residencies from Firebase
  useEffect(() => {
    getResidencies().then((residencies) => {
      setResidencies(residencies);
    });
  }, []);

  const buttonStyle = {
    width: "2rem",
    height: "2rem",
    padding: 0,
  };

  const handleCloseModal = () => setErrorMsg("");
  const handleCloseToast = () => setSuccessMsg("");

  const handleAddColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  const handleEditColorChange = (color) => {
    setEditingSelectedColor(color.hex);
  };

  // Source: https://legacy.reactjs.org/docs/handling-events.html
  const handleAddResidency = async (event) => {
    event.preventDefault();

    const address = event.target.address.value;
    const tag = event.target.tag.value;

    // if any of the fields are empty, don't add
    if (!address || !tag) {
      setErrorMsg("Please fill in all the fields");
      return;
    }

    for (let i = 0; i < residencies.length; i++) {
      // if residencies already contains this, don't add
      if (residencies[i].address === address) {
        setErrorMsg("This address already exists");
        return;
      }

      // if the tag already exists, don't add
      if (residencies[i].tag === tag) {
        setErrorMsg("This tag already exists");
        return;
      }
    }

    const color = event.target.color.value;
    // New residency
    const newResidency = {
      address: address,
      tag: tag,
      color: color,
    };

    // Add the residency to Firebase
    await addResidency(newResidency).then((id) => {
      newResidency.id = id;
      setShowAddForm(false);
      setResidencies((residencies) => {
        return [...residencies, newResidency];
      });
    });
    // Clear out the form
    event.target.address.value = "";
    event.target.tag.value = "";
    setSuccessMsg("Residency added succesfully");
  };

  // Make changes to the residency
  const handleUpdateResidency = async (event) => {
    event.preventDefault();
    const address = event.target.address.value;
    const tag = event.target.tag.value;
    const color = event.target.color.value;
    const updatedData = {
      address: address,
      tag: tag,
      color: color,
      id: editingResidencyId,
    };
    const updatedResidency = {
      id: editingResidencyId,
      data: updatedData,
    };
    // if any of the fields are empty, don't edit
    if (!address || !tag) {
      setErrorMsg("Please fill in all the fields");
      return;
    }

    for (let i = 0; i < residencies.length; i++) {
      // if residencies already contains this, don't add
      if (
        residencies[i].address === address &&
        residencies[i].id !== editingResidencyId
      ) {
        setErrorMsg("This address already exists");
        return;
      }

      // if the tag already exists, don't add
      if (
        residencies[i].tag === tag &&
        residencies[i].id !== editingResidencyId
      ) {
        setErrorMsg("This tag already exists");
        return;
      }
    }

    // Update the residency in Firebase
    await updateResidency(updatedResidency).then(() => {
      setResidencies((residencies) => {
        const index = residencies.findIndex(
          (residency) => residency.id === editingResidencyId
        );
        residencies[index] = updatedData;
        return [...residencies];
      });
      setEditingResidencyId("");
      setSuccessMsg("Residency edited succesfully");
    });
  };

  // Delete a residency 
  const handleDeleteResidency = (id) => {
    setResidencies((prevState) => {
      if (prevState.length > 1) {
        setDeleteResidencyId(id);
      } else {
        setDeleteResidencyId("")
      }
      setShowDeleteForm(true);
      return [...prevState];
    });
  };

  const onDeleteResidency = () => {
    const id = deleteResidencyId;
    deleteResidency(id);
    const newResidencies = residencies.filter(
      (residency) => residency.id !== id
    );
    setResidencies(newResidencies);
    setShowDeleteForm(false);
  };

  const onCancelDeleteResidency = () => {
    setShowDeleteForm(false);
  };

  const openEdit = (residency) => {
    setEditingResidencyId(residency.id);
    setEditingSelectedColor(residency.color);
  };

  const cancelEdit = () => {
    setEditingResidencyId("");
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <h1>Residencies</h1>
      </Row>
      {/* Source: https://react-bootstrap.netlify.app/docs/components/toasts/ */}
      <Toast position="bottom-end" show={successMsg} onClose={handleCloseToast} delay="3000" autohide>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>{successMsg}</Toast.Body>
      </Toast>
      {/* Source: https://react-bootstrap.netlify.app/docs/components/modal/ */}
      <Modal show={errorMsg} onHide={handleCloseModal} style={{ width: "60%" }}>
        <Modal.Header closeButton>
          <Modal.Title>Something went wrong</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Retry
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteForm}>
        <Modal.Header>
          <h2>
            {deleteResidencyId
              ? "Are you sure you want to delete this?"
              : "Unable to delete because it is the only one present."}
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Container className="justify-content-center text-center">
            {deleteResidencyId && (
              <Row>
                <Col>
                  <Button
                    className="w-100"
                    variant="danger"
                    onClick={onDeleteResidency}
                  >
                    Delete
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    variant="success"
                    onClick={onCancelDeleteResidency}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
            {!deleteResidencyId && (
              <Row>
                <Col>
                  <Button
                    className="w-100"
                    variant="success"
                    onClick={onCancelDeleteResidency}
                  >
                    UNDERSTOOD
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>
      </Modal>

      <Row>
        <h2>
          Add new residency{" "}
          <Button variant="secondary" onClick={() => setShowAddForm(!showAddForm)}>
            {showAddForm ? (
              <FontAwesomeIcon icon={faArrowUp} />
            ) : (
              <FontAwesomeIcon icon={faArrowDown} />
            )}
          </Button>
        </h2>
        <Collapse in={showAddForm}>
          <Form onSubmit={handleAddResidency}>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                id="address"
                type="text"
                placeholder="Enter your address"
              />
              <Form.Text className="form-text text-muted">
                The address will be used for future functionalities.
              </Form.Text>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Tag</Form.Label>
              <Form.Control
                id="tag"
                type="text"
                placeholder="Easy to remember tag"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Color</Form.Label>
              <BlockPicker
                color={selectedColor}
                onChangeComplete={handleAddColorChange}
              />
              <Form.Text>
                Select a color to associate with the address.
              </Form.Text>
              <Form.Control id="color" type="hidden" value={selectedColor} />
            </Form.Group>
            <Button type="submit" variant="primary">
              Add
            </Button>
          </Form>
        </Collapse>
      </Row>

      <hr />

      <Row>
        <h2>View residencies</h2>
      </Row>
      
      <Container>
        <Row>
          {
            // Do a for-loop for each residency that is inside residencies state variable
            // and display the address and tag.
            residencies.map((residency) => {
              return (
                <Col key={residency.id} xs={6} style={{ marginTop: "3%" }}>
                  <div className="card" key={"residency.id"}>
                    <div
                      className="card-img-top"
                      style={{
                        backgroundColor: residency.color,
                        height: "1rem",
                      }}
                    />
                    <div className="card-body">
                      {
                        // If the user is editing the residency, display an input field
                        // with the current tag. Otherwise, display the tag.
                        editingResidencyId === residency.id ? (
                          <Form onSubmit={handleUpdateResidency}>
                            <Form.Group className="form-group">
                              <Form.Label>Tag</Form.Label>
                              <Form.Control
                                id="tag"
                                type="text"
                                defaultValue={residency.tag}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                id="address"
                                type="text"
                                defaultValue={residency.address}
                              />
                            </Form.Group>
                            <Form.Group className="mb-2">
                              {/* Source: https://casesandberg.github.io/react-color/ */}
                              <BlockPicker
                                color={editingSelectedColor}
                                onChangeComplete={handleEditColorChange}
                              />
                              {/* Source: https://stackoverflow.com/questions/42352941/how-to-send-input-hidden-in-react-js */}
                              <Form.Control
                                id="color"
                                type="hidden"
                                value={editingSelectedColor}
                              />
                            </Form.Group>
                            <Button
                              className="mr-3"
                              type="submit"
                              variant="primary"
                            >
                              Edit
                            </Button>
                            <Button
                              className="btnCancel"
                              variant="warning"
                              onClick={() => cancelEdit()}
                            >
                              Cancel
                            </Button>
                          </Form>
                        ) : (
                          <>
                            <h5 className="card-title">
                              <FontAwesomeIcon icon={faTag} /> {residency.tag}
                            </h5>
                            <p className="card-text">
                              <FontAwesomeIcon icon={faLocationDot} />{" "}
                              {residency.address}
                            </p>
                            <Button
                              variant="warning"
                              style={buttonStyle}
                              className="me-2"
                              onClick={() => openEdit(residency)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                              variant="danger"
                              style={buttonStyle}
                              onClick={() =>
                                handleDeleteResidency(residency.id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          </>
                        )
                      }
                    </div>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </Container>
  );
};

export default Residencies;
