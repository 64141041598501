import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import ClothingItem from "../components/ClothingItem";
import ResidencyDropdown from "../components/ResidencyDropdown";
import { deleteMissingItemFromResidency, deleteMissingItemsFromResidency, getAllMissingClothes } from "../services/service";
import "./css/TravelWardrobe.css";

const TravelWardrobe = () => {
  const [currentResidency, setCurrentResidency] = useState({});
  const [clothes, setClothes] = useState({
    hats: [],
    upper: [],
    lower: [],
    shoes: [],
  });

  useEffect(() => {
    const fetchClothes = async () => {
      const fetchedClothes = await getAllMissingClothes(currentResidency?.id);
      if (!fetchedClothes) return { hats: [], upper: [], lower: [], shoes: [] };

      // put the residencyId in the object
      const clothesWithResidencyId = fetchedClothes.map((item) => {
        return { ...item, residencyId: currentResidency.id };
      });

      const categorizedClothes = clothesWithResidencyId.reduce(
        (acc, item) => {
          acc[item.category].push(item);
          return acc;
        },
        { hats: [], upper: [], lower: [], shoes: [] }
      );

      return categorizedClothes;
    };

    fetchClothes().then((res) => {
      setClothes(res)
    });
  }, [currentResidency?.id]);

  const deleteItem = (item) => {
    // Should also delete it from the missing clothes list in the residency
    const rfid = item.rfid
    deleteMissingItemFromResidency(currentResidency.id, rfid)
    setClothes((prevState) => {
      // All clothes that have the current residency id should be deleted
      return {
        hats: prevState.hats.filter((itm) => itm.rfid !== rfid),
        upper: prevState.upper.filter((itm) => itm.rfid !== rfid),
        lower: prevState.lower.filter((itm) => itm.rfid !== rfid),
        shoes: prevState.shoes.filter((itm) => itm.rfid !== rfid),
      }
    })
  }

  const clearClothes = () => {
    deleteMissingItemsFromResidency(currentResidency.id)
    setClothes((prevState) => {
      // All clothes that have the current residency id should be deleted
      return {
        hats: prevState.hats.filter((item) => item.residencyId !== currentResidency.id),
        upper: prevState.upper.filter((item) => item.residencyId !== currentResidency.id),
        lower: prevState.lower.filter((item) => item.residencyId !== currentResidency.id),
        shoes: prevState.shoes.filter((item) => item.residencyId !== currentResidency.id),
      }
    })
  }

  const returnCategory = (items) => {
    return items.map((item) => {
      return (
        <ClothingItem
          key={item.rfid}
          clothingKey={item.rfid}
          imageSrc={item.imagePath}
          text={item.rfid}
          onDeleteClick={() => deleteItem(item)}
          clothingTag={item.tag}
          deleteIcon="trash"
        />
      );
    });
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <h1>Travel Clothes</h1>
      </Row>
      <Row>
        <Col>
          <ResidencyDropdown
            currentResidency={currentResidency}
            setCurrentResidency={setCurrentResidency}
          />
        </Col>
        <Col>
          <Button variant="secondary" onClick={() => clearClothes()}>Clear all</Button>
        </Col>
      </Row>
      <Row>
        <Container className="justify-content-center  ">
          <h2>Hats</h2>
          <Row>{returnCategory(clothes.hats)}</Row>
          <h2>Shirts</h2>
          <Row>{returnCategory(clothes.upper)}</Row>
          <h2>Pants</h2>
          <Row>{returnCategory(clothes.lower)}</Row>
          <h2>Shoes</h2>
          <Row>{returnCategory(clothes.shoes)}</Row>
        </Container>
      </Row>
    </Container>
  );
};

export default TravelWardrobe;
