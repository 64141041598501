import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';

function QrScanner({ onResult }) {
    const [useScanner, setUseScanner] = useState(false);
    const [flashlight, setFlashlight] = useState(false);

    const handleResult = (res, err) => {
        if (res) {
            onResult(res)
            setUseScanner(false)
            setFlashlight(false)
        }
    }

    return (
        <>
            <Button
                variant={useScanner ? "danger" : "success"}
                onClick={() => setUseScanner(!useScanner)}
                style={{ marginBottom: "1%" }}
            >
                {useScanner ? "Enter manually" : "Scan barcode"}
            </Button>

            {
                useScanner && <>
                    <QrReader
                        onResult={handleResult}
                        constraints={{
                            facingMode: 'environment'
                        }}
                        style={{ width: '100%' }}
                    />

                    <Button
                        variant={flashlight ? "danger" : "success"}
                        onClick={() => setFlashlight(!flashlight)}
                        style={{ marginTop: '1%' }}
                    >
                        Turn flashlight {flashlight ? "off" : "on"}
                    </Button>
                </>
            }
        </>
    )
}

export default QrScanner;