import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import ClothingItem from "../components/ClothingItem";
import { deleteClothing, getAllClothes } from "../services/service";

import ResidencyDropdown from "../components/ResidencyDropdown";
import Category from "../util/Category";
import SubCategory from "../util/SubCategory";

const Wardrobe = () => {
  const [currentResidency, setCurrentResidency] = useState({});
  const [clothes, setClothes] = useState({
    hats: [],
    upper: [],
    lower: [],
    shoes: [],
  });
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [toDeleteClothing, setToDeleteClothing] = useState({})

  // Get all the clothes from the current residency
  useEffect(() => {
    const fetchClothes = async () => {
      const fetchedClothes = await getAllClothes(currentResidency?.id);
      if (!fetchedClothes) return { hats: [], upper: [], lower: [], shoes: [] };

      const categorizedClothes = fetchedClothes.reduce(
        (acc, item) => {
          acc[item.category].push(item);
          return acc;
        },
        { hats: [], upper: [], lower: [], shoes: [] }
      );

      return categorizedClothes;
    };

    fetchClothes().then((res) => {
      setClothes(res);
    });
  }, [currentResidency]);

  const handleDeleteClothing = (item) => {
    setToDeleteClothing(item)
    setShowDeleteForm(true)
  }

  const onDeleteClothing = () => {
    deleteClothing(currentResidency.id, toDeleteClothing.rfid, toDeleteClothing.imageId)
    setShowDeleteForm(false)
    // Used to trigger a rerender
    setCurrentResidency((prevState) => {
      const copy = { ...prevState }
      return copy
    })
  }

  const renderItems = () => {
    const categories = Object.values(Category)

    return categories.map(cat => {

      const items = clothes[cat];
      let capitalizedStr = cat.charAt(0).toUpperCase() + cat.slice(1);

      /** @type {Array} */
      const subCats = Object.keys(SubCategory).filter(k => SubCategory[k] === cat)
      // Add undefined for clohting items that do not have a subcategory 
      subCats.push(undefined)
      return (
        <Container key={capitalizedStr}>
          <Row className="justify-content-center">
            <h2>{capitalizedStr}</h2>
          </Row>
          {subCats.map(subCat => (
            <Row key={cat + subCat}>
              {subCats.length > 2 ?
                <i>{subCat ? subCat : 'Unspecified'}</i> // Only display the different subcategories if there are more than 2 (1 + undefined)
                :
                <></>
              }
              {items.map(item => {
                let low = (subCat ? subCat.toLowerCase() : undefined)
                if (item.subCategory === low) {
                  return (
                    <ClothingItem
                      key={cat + subCat + item.rfid}
                      clothingKey={item.rfid}
                      imageSrc={item.imagePath}
                      onDeleteClick={() => handleDeleteClothing(item)}
                      clothingTag={item.tag}
                      deleteIcon="trash"
                    />
                  )
                }
                else return <></>
              })}
            </Row>
          ))}
        </Container>
      )
    })
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <h1>Wardrobe</h1>
      </Row>
      <Modal show={showDeleteForm}>
        <Modal.Body>Are u sure u want to delete this?</Modal.Body>
        <Modal.Footer>
          <Container className="text-center">
            <Row>
              <Col>
                <Button variant="danger" onClick={onDeleteClothing}>Delete</Button>
              </Col>
              <Col>
                <Button variant="success" onClick={() => setShowDeleteForm(false)}>Cancel</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      <Row>
        <ResidencyDropdown
          currentResidency={currentResidency}
          setCurrentResidency={setCurrentResidency}
        />
      </Row>
      {renderItems()}
    </Container>
  );
};

export default Wardrobe;
