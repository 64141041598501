import { faEdit, faLock, faLockOpen, faPlus, faShuffle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Toast, ToastContainer } from "react-bootstrap";
import "../../src/index.css";
import ResidencyDropdown from "../components/ResidencyDropdown";
import {
  addOutfit,
  deleteOutfit,
  editOutfit,
  getAllClothes,
  getOutfitsFromResidency,
  getWeatherData,
} from "../services/service";
import ArrowClick from "../util/ArrowClick.js";
import { getMatchingClothes } from "../util/AverageColor";
import "./css/CreateOutfits.css";

const CreateOutfits = () => {
  const [currentResidency, setCurrentResidency] = useState({ tag: null });
  const [hats, setHats] = useState([]);
  const [upper, setUpper] = useState([]);
  const [lower, setLower] = useState([]);
  const [shoes, setShoes] = useState([]);
  const [currentHat, setCurrentHat] = useState(0);
  const [currentUpper, setCurrentUpper] = useState(0);
  const [currentLower, setCurrentLower] = useState(0);
  const [currentShoe, setCurrentShoe] = useState(0);
  const [outfits, setOutfits] = useState([]);
  const [currentOutfit, setCurrentOutfit] = useState(0);
  const [outfitChosen, setOutfitChosen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('')
  const [weatherData, setWeatherData] = useState(null);

  // Based on the direction, increment of decrement the current outfit index
  const navigateOutfits = (direction) => {
    const increment = direction === ArrowClick.LEFT ? -1 : 1;
    const newIndex = (currentOutfit + increment + outfits.length) % outfits.length;
    setCurrentOutfit(newIndex);
  }

  // This useEffect hook is responsible for fetching outfits associated with
  // the current residency when the current residency changes.
  // It also selects the first outfit in the list of outfits if there are any.
  useEffect(() => {
    getOutfitsFromResidency(currentResidency.id)
      .then((outfits) => {
        setOutfitChosen(outfits !== null)
        setOutfits(outfits);
        setCurrentOutfit(0);
      });
  }, [currentResidency]);

  // This useEffect hook is responsible for, on outfit change, setting for each clothing
  // option the index of the clothing item in the list of clothes.
  // tltr: choose the correct clothing items in each category based on the outfit
  useEffect(() => {
    if (!outfits || outfits?.length === 0) return;

    const outfit = outfits[currentOutfit];

    const cHat = outfit.hat;
    const cUpper = outfit.upper;
    const cLower = outfit.lower;
    const cShoes = outfit.shoes;

    // Search hatIdx based on RFID
    const hatIdx = hats.findIndex((item) => item.rfid === cHat.rfid);
    const upperIdx = upper.findIndex((item) => item.rfid === cUpper.rfid);
    const lowerIdx = lower.findIndex((item) => item.rfid === cLower.rfid);
    const shoesIdx = shoes.findIndex((item) => item.rfid === cShoes.rfid);

    setCurrentHat(hatIdx === -1 ? 0 : hatIdx);
    setCurrentUpper(upperIdx === -1 ? 0 : upperIdx);
    setCurrentLower(lowerIdx === -1 ? 0 : lowerIdx);
    setCurrentShoe(shoesIdx === -1 ? 0 : shoesIdx);

    setOutfitChosen(true);

  }, [currentOutfit]);

  // Get all the clothes associated with the current residency and put each
  // clothing item in the correct category array.
  // If there are no clothes, set the category array to an empty array.
  useEffect(() => {
    // Get all the clothes
    const fetchClothes = async () => {
      const clothes = await getAllClothes(currentResidency.id);
      if (!clothes) return;

      const hats = clothes
        .filter((item) => item.category === "hats")
      const upper = clothes
        .filter((item) => item.category === "upper")

      const lower = clothes
        .filter((item) => item.category === "lower")

      const shoes = clothes
        .filter((item) => item.category === "shoes")

      return {
        hats: hats,
        upper: upper,
        lower: lower,
        shoes: shoes,
      };
    };
    fetchClothes().then((res) => {
      if (!res) {
        setHats([]);
        setUpper([]);
        setLower([]);
        setShoes([]);
      } else {
        setHats(res.hats);
        setUpper(res.upper);
        setLower(res.lower);
        setShoes(res.shoes);
      }
    });
  }, [currentResidency]);

  // Cycling through the different clothing items 
  const arrowClick = (variable, direction) => {
    let newIndex = null;
    switch (variable) {
      case "hat":
        newIndex = direction === "left" ? currentHat - 1 : currentHat + 1;
        if (newIndex < 0) newIndex = hats.length - 1;
        if (newIndex > hats.length - 1) newIndex = 0;
        setCurrentHat(newIndex);
        break;
      case "upper":
        newIndex = direction === "left" ? currentUpper - 1 : currentUpper + 1;
        if (newIndex < 0) newIndex = upper.length - 1;
        if (newIndex > upper.length - 1) newIndex = 0;
        setCurrentUpper(newIndex);
        break;
      case "lower":
        newIndex = direction === "left" ? currentLower - 1 : currentLower + 1;
        if (newIndex < 0) newIndex = lower.length - 1;
        if (newIndex > lower.length - 1) newIndex = 0;
        setCurrentLower(newIndex);
        break;
      case "shoes":
        newIndex = direction === "left" ? currentShoe - 1 : currentShoe + 1;
        if (newIndex < 0) newIndex = shoes.length - 1;
        if (newIndex > shoes.length - 1) newIndex = 0;
        setCurrentShoe(newIndex);
        break;
      default:
        break;
    }
  };

  // Save the outfit to Firebase
  const saveOutfit = async () => {
    const residencyId = currentResidency.id;
    const cHat = hats[currentHat];
    const cUpper = upper[currentUpper];
    const cLower = lower[currentLower];
    const cShoes = shoes[currentShoe];

    // Add outfit to Firebase
    const key = await addOutfit(residencyId, cHat, cUpper, cLower, cShoes);
    setToastText('Outfit saved!')

    // Give user feedback that outfit is being saved
    setShowToast(true);

    // Update the list of outfits in the state of the app
    // If there are no outfits, create a new list of outfits
    // If there are outfits, add the new outfit to the list of outfits
    setOutfits((prevOutfits) => {
      if (prevOutfits) {
        const updatedOutfits = [
          ...prevOutfits,
          {
            key: key,
            hat: cHat,
            upper: cUpper,
            lower: cLower,
            shoes: cShoes
          }
        ];
        setCurrentOutfit((prevState) => {
          return (prevState + 1)
        })
        return updatedOutfits;
      } else {
        setCurrentOutfit(0)
        setOutfitChosen(true)
        return [{
          key: key,
          hat: cHat,
          upper: cUpper,
          lower: cLower,
          shoes: cShoes
        }]
      }
    });
  }

  // Update the selected outfit 
  const handleEditOutfit = () => {
    const outfitId = outfits[currentOutfit].key
    const residencyId = currentResidency.id;
    const cHat = hats[currentHat];
    const cUpper = upper[currentUpper];
    const cLower = lower[currentLower];
    const cShoes = shoes[currentShoe];

    // Edit the outfit in Firebase
    editOutfit(residencyId, cHat, cUpper, cLower, cShoes, outfitId);
    setShowToast(true);
    setToastText('Outfit edited!')

    // Update the outfits state with the modified outfit
    setOutfits((prevOutfits) => {
      const updatedOutfits = [...prevOutfits];
      const outfitIndex = updatedOutfits.findIndex((outfit) => outfit.key === outfitId);

      if (outfitIndex !== -1) {
        updatedOutfits[outfitIndex] = {
          ...updatedOutfits[outfitIndex],
          hat: cHat,
          upper: cUpper,
          lower: cLower,
          shoes: cShoes
        };
      }

      return updatedOutfits;
    });
  }

  // Delete the current outfit 
  const handleDeleteOutfit = () => {
    const outfitId = outfits[currentOutfit].key
    const residencyId = currentResidency.id;

    // Delete the outfit from Firebase
    deleteOutfit(residencyId, outfitId)
    setShowToast(true);
    setToastText('Outfit deleted!')

    // Update the outfits state with the modified outfit
    setCurrentOutfit(0)
    setOutfits((prevOutfits) => {
      const updatedOutfits = [...prevOutfits];
      const outfitIndex = updatedOutfits.findIndex((outfit) => outfit.key === outfitId);
      if (outfitIndex !== -1) {
        updatedOutfits.splice(outfitIndex, 1);
      }
      return updatedOutfits;
    });
  }

  const colStyle = {
    position: 'relative'
  }

  const lockButtonStyle = {
    position: 'absolute',
    bottom: 0,
    right: '-1.75rem',

    width: '2rem',
    height: '2rem',
    padding: 0
  }

  const [variants, setVariants] = useState(['success', 'success', 'success', 'success'])
  const [loadingSuggestion, setLoadingSuggestion] = useState(false)

  // Outfit randomizer, if a piece of clothing is locked, it won't change during the randomization
  const randomiseOutfit = () => {
    setOutfitChosen(false);
    if (variants[0] === 'success') { setCurrentHat(Math.floor(Math.random() * hats.length)); }
    if (variants[1] === 'success') { setCurrentUpper(Math.floor(Math.random() * upper.length)); }
    if (variants[2] === 'success') { setCurrentLower(Math.floor(Math.random() * lower.length)); }
    if (variants[3] === 'success') { setCurrentShoe(Math.floor(Math.random() * shoes.length)); }
  };

  // Toggle the lock of a clothing item 
  const toggleLock = (i) => {
    setVariants(vs => {
      const newVariants = [...vs]
      const current = vs[i]
      newVariants[i] = current === 'danger' ? 'success' : 'danger'
      return newVariants
    })
  }

  let idxToCat = {
    0: hats[currentHat],
    1: upper[currentUpper],
    2: lower[currentLower],
    3: shoes[currentShoe]
  };

  let catToState = {
    'hats': [hats, setCurrentHat],
    'upper': [upper, setCurrentUpper],
    'lower': [lower, setCurrentLower],
    'shoes': [shoes, setCurrentShoe]
  }

  // Suggestion system 
  const handleSuggestion = async () => {
    // Remove current outfit text
    setOutfitChosen(false);

    // Disable the button 
    setLoadingSuggestion(true)

    let input = []
    // Get all the images that are locked in 
    for (let i = 0; i < variants.length; i++) {
      const element = variants[i];
      if (element === 'danger') {
        // This category is locked in 
        input.push(idxToCat[i])
      }
    }

    // Get the best matching clothes
    const output = await getMatchingClothes(input, {
      'hats': hats,
      'upper': upper,
      'lower': lower,
      'shoes': shoes
    })

    // Update the UI 
    Object.values(output).forEach(e => {
      const { category } = e
      const [arr, setter] = catToState[category]

      const i = arr.indexOf(e)
      setter(i)
    })

    // Re-enable the button
    setLoadingSuggestion(false)
  }

  const updateCurrentResidency = (residency) => {
    setCurrentResidency((prevState) => {
      setCurrentHat(0);
      setCurrentUpper(0);
      setCurrentLower(0);
      setCurrentShoe(0);
      return residency
    });
  }

  useEffect(() => {
    // Fetch user's location and weather data
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          getWeatherData(latitude, longitude).then((weatherData) => {
            setWeatherData(weatherData);
          })
        },
        error => {
          //
        }
      );
    }
  }, []);

  const buttonStyle = {
    width: "2rem",
    height: "2rem",
    padding: 0,
  };

  return (
    <Container fluid>

      <Row className="justify-content-center">
        <h1>Manage Outfits</h1>
      </Row>

      {/* Show current forecast */}
      {
        weatherData ?
          <Row>
            <MDBContainer>
              <MDBRow
                className="justify-content-center align-items-center"
                style={{ color: "#282828" }}
              >
                <MDBCol md="6" lg="4" xl="3">
                  <MDBCard className="mb-4 gradient-custom">
                    <MDBCardBody className="p-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3 className="display-4">
                            <strong>{weatherData.current.temp_c}°C</strong>
                          </h3>
                          <p className="text-muted mb-0">
                            {weatherData.location.name}, {weatherData.location.country}
                          </p>
                        </div>
                        <img
                          src={weatherData.current.condition.icon}
                          alt={weatherData.current.condition.text}
                          width="80rem"
                        />
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </Row>
          :
          <Row className='my-3 justify-content-center align-items-center'>
            <Spinner animation="border" role="status" className='mr-1'>
              <span className="visually-hidden">Loading weather</span>
            </Spinner>
            Loading weather forecast...
          </Row>
      }

      {/* Informational Toast */}
      <Row>
        <ToastContainer className="p-3" position="bottom-end">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            bg="info"
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Row>

      {/* Residency drowpdown */}
      <Row className="justify-content-center" >
        <ResidencyDropdown
          currentResidency={currentResidency}
          setCurrentResidency={updateCurrentResidency}
        />
      </Row>

      {/* Show number of outfits */}
      <Row className="items-center text-center">
        <p>Outfits: {outfits?.length || 0}</p>
      </Row>

      {/* Hat */}
      <Row className="items-center text-center mt-4 mb-4">
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-left"
              onClick={() => arrowClick("hat", "left")}
            />
          </Button>
        </Col>
        <Col xs={4} xl={2}>
          <div style={colStyle}>
            <img
              src={
                hats.length > 0
                  ? hats[currentHat].imagePath
                  : "https://via.placeholder.com/125x125"
              }
              alt="headwear"
              className="rounded-lg resized-image"
            />

            <Button style={lockButtonStyle} variant={variants[0]} onClick={() => toggleLock(0)}>
              <FontAwesomeIcon icon={variants[0] === 'danger' ? faLock : faLockOpen} />
            </Button>
          </div>
        </Col>
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-right"
              onClick={() => arrowClick("hat", "right")}
            />
          </Button>
        </Col>
      </Row>

      {/* Upper */}
      <Row className="items-center text-center mt-4 mb-4">
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-left"
              onClick={() => arrowClick("upper", "left")}
            />
          </Button>
        </Col>
        <Col xs={4} xl={2}>
          <div style={colStyle}>
            <img
              src={
                upper.length > 0
                  ? upper[currentUpper].imagePath
                  : "https://via.placeholder.com/125x125"
              }
              alt="topwear"
              className="rounded-lg resized-image"
            />

            <Button style={lockButtonStyle} variant={variants[1]} onClick={() => toggleLock(1)}>
              <FontAwesomeIcon icon={variants[1] === 'danger' ? faLock : faLockOpen} />
            </Button>
          </div>
        </Col>
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-right"
              onClick={() => arrowClick("upper", "right")}
            />
          </Button>
        </Col>
      </Row>

      {/* Lower */}
      <Row className="items-center text-center mt-4 mb-4">
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-left"
              onClick={() => arrowClick("lower", "left")}
            />
          </Button>
        </Col>
        <Col xs={4} xl={2}>
          <div style={colStyle}>
            <img
              src={
                lower.length > 0
                  ? lower[currentLower].imagePath
                  : "https://via.placeholder.com/125x125"
              }
              alt="pants"
              className="rounded-lg resized-image"
            />

            <Button style={lockButtonStyle} variant={variants[2]} onClick={() => toggleLock(2)}>
              <FontAwesomeIcon icon={variants[2] === 'danger' ? faLock : faLockOpen} />
            </Button>
          </div>
        </Col>
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-right"
              onClick={() => arrowClick("lower", "right")}
            />
          </Button>
        </Col>
      </Row>

      {/* Shoes */}
      <Row className="items-center text-center mt-4 mb-4">
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-left"
              onClick={() => arrowClick("shoes", "left")}
            />
          </Button>
        </Col>
        <Col xs={4} xl={2}>
          <div style={colStyle}>
            <img
              src={
                shoes.length > 0
                  ? shoes[currentShoe].imagePath
                  : "https://via.placeholder.com/125x125"
              }
              alt="shoes"
              className="rounded-lg resized-image"
            />

            <Button style={lockButtonStyle} variant={variants[3]} onClick={() => toggleLock(3)}>
              <FontAwesomeIcon icon={variants[3] === 'danger' ? faLock : faLockOpen} />
            </Button>
          </div>
        </Col>
        <Col>
          <Button variant="light">
            <i
              className="text-4xl bi bi-arrow-right"
              onClick={() => arrowClick("shoes", "right")}
            />
          </Button>
        </Col>
      </Row>

      {/* Buttons */}
      <Row className="items-center text-center justify-center">
        <Row>
          <Col xs={4}>
            <Button disabled={!outfits || outfits?.length < 1} className="m-2" onClick={() => navigateOutfits(ArrowClick.LEFT)}>
              Previous
            </Button>
          </Col>
          <Col xs={4} style={{ visibility: (outfitChosen && outfits.length > 0) ? 'visible' : 'hidden' }}>
            <h6>Current Outfit: {currentOutfit + 1}</h6>
          </Col>
          <Col xs={4}>
            <Button disabled={!outfits || outfits?.length < 1} className="m-2" onClick={() => navigateOutfits(ArrowClick.RIGHT)}>
              Next
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 mb-4">
          <Col md={1}></Col>
          <Col xs={2} md={1}>
            <Button style={buttonStyle} disabled={
              hats.length < 1 ||
              upper.length < 1 ||
              lower.length < 1 ||
              shoes.length < 1
            } onClick={saveOutfit}><FontAwesomeIcon icon={faPlus} /></Button>
          </Col>
          <Col xs={2} md={1}>
            <Button
              variant="warning" disabled={
                hats.length < 1 ||
                upper.length < 1 ||
                lower.length < 1 ||
                shoes.length < 1 ||
                !outfitChosen
              } onClick={handleEditOutfit} style={buttonStyle}><FontAwesomeIcon icon={faEdit} /></Button>
          </Col>
          <Col xs={2} md={1}>
            <Button disabled={
              hats.length < 1 ||
              upper.length < 1 ||
              lower.length < 1 ||
              shoes.length < 1 ||
              !outfitChosen
            } style={buttonStyle} variant="danger" onClick={handleDeleteOutfit}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </Col>
          <Col xs={2} md={1}>
            <Button style={buttonStyle} onClick={randomiseOutfit}>
              <FontAwesomeIcon icon={faShuffle} />
            </Button>
          </Col>
          <Col xs={2} md={1}>
            <Button disabled={loadingSuggestion} onClick={handleSuggestion}>
              {loadingSuggestion ? 'Loading ...' : 'Suggest'}
            </Button>
          </Col>
        </Row>
        <Col md={4}></Col>
      </Row>
    </Container >
  );
};

export default CreateOutfits;
