import { useEffect, useState } from "react";
import {
  getLocalCurrentResidency,
  getResidencies,
  getTextColor,
  setLocalCurrentResidency,
} from "../services/service";
import { Button } from "react-bootstrap";

const ResidencyDropdown = ({currentResidency, setCurrentResidency}) => {
  const [residencies, setResidencies] = useState([]);

  const buttonStyle = {
    minWidth: "10rem",
    backgroundColor: currentResidency.color || "white",
    color: getTextColor(currentResidency.color || "grey"),
  };

  const selectResidency = (residency) => {
    setCurrentResidency(residency);
    setLocalCurrentResidency(residency);
  };

  // Fetch the current selected residency, and the list of residencies. 
  useEffect(() => {
    const initResidency = async () => {
      const localCurrentResidency = getLocalCurrentResidency();
      const residencies = await getResidencies();
      setResidencies(residencies);
      selectResidency(localCurrentResidency || residencies[0]);
    };
    initResidency();
  }, []);

  return (
    <>
      <div className="dropdown text-center">
        <Button
          style={buttonStyle}
          variant="secondary"
          className=" dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {currentResidency.tag ? currentResidency.tag : "Select a Residency"}
        </Button>
        <ul className="dropdown-menu items-center text-center">
          {residencies.map((residency) => (
            <li key={residency.id}>
              <button
                style={{
                  backgroundColor: residency.color,
                  color: getTextColor(residency.color),
                }}
                className="dropdown-item"
                onClick={() => selectResidency(residency)}
              >
                {residency.tag}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ResidencyDropdown;
