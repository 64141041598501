import Category from "./Category"
import SubCategory from "./SubCategory"

import hatMask from "../overlays/masks/hat_mask.png"

import dressMask from "../overlays/masks/dress_mask.png"
import longsleeveMask from "../overlays/masks/longsleeve_mask.png"
import outwearMask from "../overlays/masks/outwear_mask.png"
import shirtMask from "../overlays/masks/shirt_mask.png"
import tshirtMask from "../overlays/masks/shirt_mask.png"

import pantMask from "../overlays/masks/pants_mask.png"
import shortMask from "../overlays/masks/shorts_mask.png"
import skirtMask from "../overlays/masks/skirt_mask.png"

import shoeMask from "../overlays/masks/shoes_mask.png"


/**
 * @param {String} image Image URI, input
 * @param {String} outline Mask URI
 * @returns {Promise<Array>} [str, obj]: The string is a "rgb(r,g,b)" with the respective values. The obj is the object representation: { r: rValue, g: gValue, b: bValue }
 */
export async function getAverageColor(image, outline) {
    const img = new Image()
    img.crossOrigin = "anonymous"
    img.src = image
    img.width = 500
    img.height = 500
    const msk = new Image()
    msk.src = outline

    return Promise.all([
        new Promise(res => img.addEventListener('load', res)),
        new Promise(res => msk.addEventListener('load', res))
    ]).then(() => {
        const imgCanvas = document.createElement('canvas')
        const mskCanvas = document.createElement('canvas')

        imgCanvas.width = mskCanvas.width = img.naturalWidth
        imgCanvas.height = mskCanvas.height = img.naturalHeight

        const imgContext = imgCanvas.getContext('2d')
        const mskContext = mskCanvas.getContext('2d')
        imgContext.drawImage(img, 0, 0)
        mskContext.drawImage(msk, 0, 0)

        const imgData = imgContext.getImageData(0, 0, img.width, img.height)
        const mskData = mskContext.getImageData(0, 0, msk.width, msk.height)


        let totalR = 0
        let totalG = 0
        let totalB = 0
        let count = 0

        for (let y = 0; y < img.height; y++) {
            for (let x = 0; x < img.width; x++) {
                const index = (y * img.width + x) * 4
                const insideMask = mskData.data[index + 3] > 0;

                if (insideMask) {
                    totalR += imgData.data[index]
                    totalG += imgData.data[index + 1]
                    totalB += imgData.data[index + 2]
                    count++;
                }
            }
        }

        const avgR = Math.round(totalR / count)
        const avgG = Math.round(totalG / count)
        const avgB = Math.round(totalB / count)

        const averageColor = `rgb(${avgR},${avgG},${avgB})`

        const result = {
            r: avgR,
            g: avgG,
            b: avgB
        }

        return [averageColor, result]
    })
}

/**
 * Returns the eucledian distance between two RGB objects 
 * @param {Object} c1 RGB Object 1
 * @param {Object} c2 RGB Object 2
 * @returns {Number} Eucledian color distance 
 */
function eucledianColordistance(c1, c2) {
    let diffR = c1.r - c2.r
    let diffG = c1.g - c2.g
    let diffB = c1.b - c2.b

    return Math.sqrt(diffR * diffR + diffG * diffG + diffB * diffB)
}

/**
 * *Debugging* 
 * Returns the most similar test image. Used on the /Debug page 
 * @param {Object} avgRgb: Object that has keys r,g and b 
 * @param {String} mask : Image URI to mask 
 * @returns {Promise<String>} Image that has the closest rgb value to the given average rgb
 */
export async function getMostSimilar(avgRgb, mask) {
    const importAll = (r) => {
        return r.keys().map(r);
    };

    const tests = importAll(
        require.context("../../src/test/test", true, /\.(png|jpe?g|svg|JPE?G)$/)
    )

    let mostSimilar = tests[0]
    let closestDist = Infinity

    for (let index = 0; index < tests.length; index++) {
        const current = tests[index];

        const output = await getAverageColor(current, mask)
        const currentAverage = output[1]

        const dist = eucledianColordistance(avgRgb, currentAverage)

        if (dist < closestDist) {
            mostSimilar = current
            closestDist = dist
        }
    }

    return mostSimilar
}

/**
 * pickFromList
 * @param {Object} avgRgb Average RGB value to match
 * @param {Array} imgs Array of images from which to pick
 * @param {String} category Category of clothing the images belong to
 * @returns {Promise<Integer>} Index of the best matching image in the array
 */
async function pickFromList(avgRgb, imgs, category) {
    const mask = categoryToMask(category)

    let mostSimilar = 0
    let closest = Infinity

    for (let i = 0; i < imgs.length; i++) {
        const current = imgs[i]

        const [str, rgb] = await getAverageColor(current, mask)
        const dist = eucledianColordistance(avgRgb, rgb)

        if (dist < closest) {
            mostSimilar = i
            closest = dist
        }
    }

    return mostSimilar
}

/**
 * Returns the mask image URI based on the given category string
 * @param {String} category 
 * @returns {String} imageURI
 */
function categoryToMask(category) {
    switch (category) {
        case Category.HATS:
            return hatMask
        case Category.UPPER:
            return shirtMask
        case Category.LOWER:
            return pantMask
        case Category.SHOES:
            return shoeMask
        default:
            console.error(`Invalid category: ${category}`)
    }
}

function subcatToMask(subcat) {
    switch (subcat) {
        case "hat":
            return hatMask
        case "dress":
            return dressMask
        case "longsleeve":
            return longsleeveMask
        case "outwear":
            return outwearMask
        case "t-shirt":
        case "shirt":
            return shirtMask
        case "pants":
            return pantMask
        case "shorts":
            return shortMask
        case "skirt":
            return skirtMask
        case "shoes":
            return shoeMask

        default:
            console.error(`Invalid subcategory: ${subcat}`)
    }
}

/**
 * Returns the sum of the two RGB objects
 * @param {Object} o1 RGB Object 1
 * @param {Object} o2 RGB Object 2
 * @returns {Object} RGB Object containing the sum of values 
 */
function sumRgbs(o1, o2) { 
    return {
        r: o1.r + o2.r,
        g: o1.g + o2.g,
        b: o1.b + o2.b
    }
}

/**
 * Returns an object with one clothing of each category based on matching colors 
 * @param {Object} inputClothes: Clothes that are locked; i.e. can't be changed. Color matching based on this 
 * @param {Object} variableClothes: All clothes
 * @returns {Promise<Object>} output
 */
export async function getMatchingClothes(inputClothes, variableClothes) {
    const keys = ['hats', 'upper', 'lower', 'shoes']
    let output = {}

    // Average rgb of the input clothing 
    let sum = {
        r: 0, 
        g: 0, 
        b: 0
    }
    for (let i = 0; i < inputClothes.length; i++) {
        const current = inputClothes[i]
        const { category, subCategory, imagePath } = current
        const mask = subcatToMask(subCategory)
        output[category] = current

        const [str, rgbObject] = await getAverageColor(imagePath, mask)
        sum = sumRgbs(sum, rgbObject)
    }

    const averageRgb = {
        r: sum.r / inputClothes.length,
        g: sum.g / inputClothes.length,
        b: sum.b / inputClothes.length,
    }

    // Get matching clothes 
    for (let i = 0; i < keys.length; i++) {
        const current = keys[i]
        if (!(current in output)) {
            const arr = variableClothes[current]
            if (arr.length > 0) {
                const imgs = arr.map(o => o.imagePath)
                const catg = arr[0].category

                const idx = await pickFromList(averageRgb, imgs, catg)
                output[catg] = arr[idx]
            }
        }
    }

    return output
}