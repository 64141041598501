import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import AddClothes from './pages/AddClothes';
import CreateOutfits from './pages/CreateOutfits';
import Dashboard from './pages/Dashboard';
import Debug from './pages/Debug';
import Outfits from './pages/Outfits';
import Residencies from './pages/Residencies';
import Settings from './pages/Settings';
import Shopping from './pages/Shopping';
import TravelWardrobe from './pages/TravelWardrobe';
import Wardrobe from './pages/Wardrobe';
import Login from './pages/authentication/Login';
import Register from './pages/authentication/Register';

function App() {
  return (
    <>
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/add-clothes' element={<AddClothes />} />
          <Route path='/create-outfits' element={<CreateOutfits />} />
          <Route path='/residencies' element={<Residencies />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/wardrobe' element={<Wardrobe />} />
          <Route path='/travel-wardrobe' element={<TravelWardrobe />} />
          <Route path='/outfits' element={<Outfits />} />
          <Route path='/shopping' element={<Shopping />} />
          <Route path='/debug' element={<Debug />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;