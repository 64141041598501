import * as tmImage from '@teachablemachine/image';
import { useEffect, useRef, useState } from "react";
import { Toast, ToastContainer, Button, Container, Dropdown, InputGroup, Spinner, Row, Col } from "react-bootstrap";

import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import Category from "../util/Category";
import SubCategory from "../util/SubCategory";
import { getEverything } from '../services/service';
import { getMatchingClothes } from '../util/AverageColor';

function Shopping() {
  const [overlayIdx, setOverlayIdx] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [image, setImage] = useState();
  const [showToast, setShowToast] = useState(false);
  const [overlays, setOverlays] = useState([]);
  const [isTeachableMachineLoaded, setIsTeachableMachineLoaded] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [currentPrediction, setCurrentPrediction] = useState("");
  const [loopIntervalId, setLoopIntervalId] = useState("");

  const manualOverlayChosenRef = useRef(false);

  const [hats, setHats] = useState([])
  const [upper, setUpper] = useState([])
  const [lower, setLower] = useState([])
  const [shoes, setShoes] = useState([])

  const [cHat, setCHat] = useState();
  const [cUpper, setCUpper] = useState();
  const [cLower, setCLower] = useState();
  const [cShoes, setCShoes] = useState();

  const [loading, setLoading] = useState(false);

  // Dynamically added via useEffect
  // Map the index of the overlay to that overlay's category.
  const [idxToCat, setIdxToCat] = useState({});

  const TM_URL = "https://teachablemachine.withgoogle.com/models/di7HE36Th/";

  let model, webcam;

  // Get all the clothes from Firebase (from all residencies)
  useEffect(() => {
    getEverything().then(clothings => {
      setHats(clothings.filter(o => o.category === Category.HATS))
      setUpper(clothings.filter(o => o.category === Category.UPPER))
      setLower(clothings.filter(o => o.category === Category.LOWER))
      setShoes(clothings.filter(o => o.category === Category.SHOES))
    })
  }, [])

  // Create a loop interval that runs every second.
  // This loop makes predictions of the subclass of the clothing
  // item in frame every second.
  // Method is useful when the user takes a picture and no further
  // prediction needs to be made.
  const makeLoopInterval = () => {
    removeLoopInterval();
    const intervalId = setInterval(() => {
      if (!manualOverlayChosenRef.current) {
        loop();
      }
    }, 1000);

    setLoopIntervalId(intervalId);
  }

  // Remove the loop interval of predictions.
  const removeLoopInterval = () => {
    if (loopIntervalId) {
      clearInterval(loopIntervalId);
    }
  }

  useEffect(() => {
    // make only predictions when the image is not loaded (= no picture taken yet)
    if (!image && predictions.length > 0) {
      const prediction = predictions[0];
      // prediction is an array of objects with keys: className, probability.
      // Find the highest probability one.
      let idx = 0;
      let highestProbability = 0;
      for (let i = 0; i < prediction.length; i++) {
        if (prediction[i].probability > highestProbability) {
          highestProbability = prediction[i].probability;
          idx = i;
        }
      }
      // Subcategory -> Category
      // get the subcategory and then get the category based on the subcategory
      const subCategory = prediction[idx].className;
      const category = SubCategory[subCategory];

      // Get the key of the category since we're using enums
      const categoryKey = getKeyByValue(Category, category);

      // Get the index of overlay of the highest probability class via catToIdx
      const categoryIdx = getKeyByValue(idxToCat, categoryKey);

      // Use the idx to set the overlay
      setOverlayIdx(categoryIdx);
      setCurrentPrediction(subCategory);
    }
  }, [predictions]);

  // This useEffect hook ensures that the Teachable Machine model is loaded and initialized when the component mounts.
  useEffect(() => {
    if (!isTeachableMachineLoaded) {
      setIsTeachableMachineLoaded(() => {
        init();
        return true;
      });
    }
  }, []);

  // Load the image model and setup the webcam
  // Default Teachable Machine code from when you export your model.
  // Source: https://github.com/googlecreativelab/teachablemachine-community/blob/master/snippets/markdown/image/tensorflowjs/javascript.md
  async function init() {
    const modelURL = TM_URL + "model.json";
    const metadataURL = TM_URL + "metadata.json";

    // load the model and metadata
    model = await tmImage.load(modelURL, metadataURL);

    // Convenience function to setup a webcam
    const flip = true; // whether to flip the webcam
    // Use a high resolution webcam to get a better image for prediction
    webcam = new tmImage.Webcam(1200, 1800, flip); // width, height, flip
    await webcam.setup(); // request access to the webcam
    await webcam.play();
    makeLoopInterval();

    // append elements to the DOM
    document.getElementById("webcam-container").appendChild(webcam.canvas);
  }

  async function loop() {
    // If the model isn't loaded in yet or there is an image; return
    if (!model || image) return;

    webcam.update(); // update the webcam frame
    await predict();
  }

  // run the webcam image through the image model
  async function predict() {
    // If an image is already taken or the loop interval is not set; return
    if (image) return;

    // If the camera hasn't initialized yet; return 
    if (!webcam.canvas) return;

    // predict can take in an image, video or canvas html element
    const prediction = await model.predict(webcam.canvas);
    setPredictions((prevState) => {
      // Clear out the previous array
      const emptyArray = prevState.splice();

      // Add the new predictions
      emptyArray.push(prediction);

      return emptyArray;
    });
  }

  // On manual overlay change, set the current subcategory to that of 
  // the first subcategory of the current selected category of the overlay.
  const setCurrentPredictionToFirstInSubCategory = (updatedOverlayIdx) => {
    const currentCat = Category[idxToCat[updatedOverlayIdx]];
    // Get the first subcategory of the current category
    const subCategory = Object.keys(SubCategory).find(key => SubCategory[key] === currentCat);
    setCurrentPrediction(subCategory);
  }

  // Cycle through overlays
  const handleNextClick = () => {
    manualOverlayChosenRef.current = true;
    setOverlayIdx((prev) => {
      const nextIdx = (prev + 1) % overlays.length
      setCurrentPredictionToFirstInSubCategory(nextIdx);
      return nextIdx
    });
  };
  const handlePrevClick = () => {
    manualOverlayChosenRef.current = true;
    setOverlayIdx((prev) => {
      const prevIdx = (prev === 0 ? overlays.length - 1 : prev - 1)
      setCurrentPredictionToFirstInSubCategory(prevIdx);
      return prevIdx
    });
  };

  useEffect(() => {
    if (image) {
      loadRecommendation(image)
    }
  }, [currentPrediction])

  const loadRecommendation = (inputImage) => {
    setLoading(true)

    const input = [{
      category: Category[currentCategory()],
      subCategory: currentPrediction.toLowerCase(),
      imagePath: inputImage
    }]

    const clothes = {
      hats: hats,
      upper: upper,
      lower: lower,
      shoes: shoes
    }

    const catToState = {
      'hats': setCHat,
      'upper': setCUpper,
      'lower': setCLower,
      'shoes': setCShoes
    }

    // Recommended outfit 
    getMatchingClothes(input, clothes).then(output => {

      Object.values(output).forEach(e => {
        const { category } = e
        const setter = catToState[category]

        // Set the recommened clothing item in the overview
        setter(e.imagePath)
      })

      setLoading(false)
    })
  }

  // Image captured
  const handlePhoto = (uri) => {
    clearInterval(loopIntervalId);
    const canvas = document.createElement('canvas')
    const img = new Image()
    img.src = uri
    img.onload = () => {
      const ctx = canvas.getContext('2d')
      const aspectRatio = 1
      const w = img.width > img.height ? img.height * aspectRatio : img.width
      const h = img.height > img.width ? img.width * aspectRatio : img.height
      canvas.width = w
      canvas.height = h

      ctx.drawImage(img, 0, 0, w, h)
      const squareImage = canvas.toDataURL()

      // Set the captured image 
      fillinCurrentImage(squareImage)
      setImage(squareImage)

      loadRecommendation(squareImage);
    }
  };

  // Setting the captured image 
  function fillinCurrentImage(img) {
    let current = Category[currentCategory()]
    switch (current) {
      case Category.HATS:
        setCHat(img)
        break;
      case Category.UPPER:
        setCUpper(img)
        break;
      case Category.LOWER:
        setCLower(img)
        break;
      case Category.SHOES:
        setCShoes(img)
        break;

      default:
        console.error(`Invalid category: ${current}`)
        break;
    }
  }

  // Remove captured image
  const resetImage = () => {
    setCHat(undefined)
    setCUpper(undefined)
    setCLower(undefined)
    setCShoes(undefined)
    manualOverlayChosenRef.current = false;
    setImage();
  };

  // Toggle overlays whenever the image state changes
  useEffect(() => {
    setShowOverlay(!showOverlay);

    // Only when the image is reset, make the loop interval again
    if (!image) {
      makeLoopInterval();
    }
    // eslint-disable-next-line
  }, [image]);

  useEffect(() => {

    // Import overlays through context
    const importAll = (r) => {
      r.keys().forEach((r, idx) => {
        let overlayName = r.replace(/^.*[\\/]/, '');
        // Also remove the .* from the name
        overlayName = overlayName.replace(/\..*/, '');
        const key = getKeyByValue(Category, overlayName);
        idxToCat[idx] = key;
      });

      return r.keys().map(r);
    };

    // Import ALL overlays from the overlays folder through context
    const overlayContext = importAll(
      require.context("../../src/overlays", false, /\.(png|jpe?g|svg|JPE?G)$/)
    );

    setOverlays(overlayContext);
  }, []);

  // Efficiently get the key of value in an object
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  const buttonStyle = {
    minWidth: '8rem'
  }

  const allSubCategoriesOfCategory = () => {
    const cCat = Category[currentCategory()];

    // Get all keys where the values are equal to cCat
    const keys = Object.keys(SubCategory).filter(key => SubCategory[key] === cCat);
    return keys;
  }

  const currentCategory = () => {
    return idxToCat[overlayIdx];
  }

  const predictionStyling = (shouldShowOnPrediction) => {
    if (shouldShowOnPrediction) return { display: currentPrediction ? '' : 'none' };
    return { display: currentPrediction ? 'none' : '' };
  }

  return (
    <Container>

      {/* Webcam for Teachable Machine */}
      <Row style={{ display: 'none' }}>
        <div id="webcam-container"></div>
      </Row>

      <Row>
        <ToastContainer className="p-3" position="bottom-end">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            bg="info"
          >
            <Toast.Body>Image saved!</Toast.Body>
          </Toast>
        </ToastContainer>
      </Row>

      <Row className="justify-content-center mx-3">
        <h1>Shopping</h1>
        Check if new clothes could match some of your clothing items!
      </Row>

      <Row style={predictionStyling(false)} className="justify-content-center align-items-center">
        <Spinner animation="border" role="status" className='mr-1'>
          <span className="visually-hidden">Starting camera</span>
        </Spinner>
        Starting camera...
      </Row>

      <Row style={predictionStyling(true)} >
        <div
          className="d-flex justify-content-center my-3"
          style={{ position: "relative" }}
        >
          {image ? (
            <img id="img-src" alt="" src={image} />
          ) : (
            <Camera
              onCameraStart={() => setShowOverlay(true)}
              onCameraError={() => setShowOverlay(false)}
              imageType="png"
              onTakePhoto={handlePhoto}
              idealFacingMode="environment"
              isImageMirror={false}
            />
          )}
          {showOverlay && (
            <img
              src={overlays[overlayIdx]}
              alt="overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
        <div className="overlay-container">
          {image ? (
            <>
              {currentCategory() === "" ? (
                <></>
              ) : (
                <div className="d-flex justify-content-center mb-3">
                  Category: {currentCategory()}
                </div>
              )}

              <Container>
                <Row>
                  <Col xs={4}>
                    <Button variant="danger" onClick={resetImage}>
                      Clear
                    </Button>
                  </Col>
                  <Col xs={8}>
                    <InputGroup>
                      <InputGroup.Text id="subcategory">Subcat.</InputGroup.Text>
                      <Dropdown>
                        <Dropdown.Toggle
                          aria-label="Subcategory"
                          aria-describedby="subcategory" id="dropdown-basic">
                          {currentPrediction}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {allSubCategoriesOfCategory().map((subcat) => (
                            <Dropdown.Item
                              key={subcat}
                              onClick={() => setCurrentPrediction(subcat)}
                            >
                              {subcat}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup>
                  </Col>
                </Row>

                {/* Show the recommended outfit */}
                {loading ?
                  <Row className='my-3 justify-content-center align-items-center'>
                    <Spinner animation="border" role="status" className='mr-1'>
                      <span className="visually-hidden">Loading suggestion</span>
                    </Spinner>
                    Loading suggestion...
                  </Row>
                  :
                  <>
                    <Row className='my-2 justify-content-center'>
                      Here is a recommended outfit based on this new item!
                    </Row>
                    <Row className='my-3'>
                      <Col>
                        <img
                          alt='hat'
                          className='rounded-lg resized-image'
                          src={cHat ? cHat : 'https://via.placeholder.com/125x125'}
                        />
                      </Col>

                      <Col>
                        <img
                          alt='upper'
                          className='rounded-lg resized-image'
                          src={cUpper ? cUpper : 'https://via.placeholder.com/125x125'}
                        />
                      </Col>

                      <Col>
                        <img
                          alt='lower'
                          className='rounded-lg resized-image'
                          src={cLower ? cLower : 'https://via.placeholder.com/125x125'}
                        />
                      </Col>

                      <Col>
                        <img
                          alt='shoes'
                          className='rounded-lg resized-image'
                          src={cShoes ? cShoes : 'https://via.placeholder.com/125x125'}
                        />
                      </Col>
                    </Row>
                  </>
                }

              </Container>
            </>
          ) : (
            <div className="d-flex justify-content-center gap-3">
              <Button style={buttonStyle} onClick={handlePrevClick}>Previous</Button>
              <Button style={buttonStyle} onClick={handleNextClick}>Next</Button>
            </div>
          )}
        </div>
      </Row>

    </Container >
  );
}

export default Shopping;