import { faEdit, faTag, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { editRfidClothing, editTagClothing } from "../services/service";
import "./ClothingItem.css";
import QrScanner from "./QrScanner";

function ClothingItem({
  clothingKey,
  imageSrc,
  onDeleteClick,
  clothingTag,
  deleteIcon,
}) {
  const buttonStyle = {
    width: "2rem",
    height: "2rem",
    padding: 0,
  };

  const [originalRFID, setOriginalRFID] = useState(clothingKey)
  const [showEditRfidModal, setShowEditRfidModal] = useState(false);
  const [rfid, setRfid] = useState("");
  const [originalTag, setOriginalTag] = useState(clothingTag)
  const [tag, setTag] = useState("")
  const [showEditTagModal, setShowEditTagModal] = useState(false);

  // Editing the RFID 
  const handleEditRFIDSaveChanges = async () => {
    await editRfidClothing(clothingKey, rfid).then(() => {
      setRfid((prevState) => {
        setShowEditRfidModal(false);
        setOriginalRFID(prevState)
        return ""
      })
    })
  };

  // Editing the tag of the clothing item
  const handleEditTagSaveChanges = async () => {
    await editTagClothing(originalRFID, tag).then(() => {
      setTag((prevState) => {
        setShowEditTagModal(false);
        setOriginalTag(prevState);
        return ""
      })
    })
  };

  return (
    <Col xs={6} sm={4} md={3} lg={2} xl={2}>
      <Card className="mb-3 p-0">
        <Card.Img className="card-image" variant="top" src={imageSrc} />
        <Card.Body>
          <Card.Text>
            RFID: {originalRFID}
          </Card.Text>
          {
            originalTag &&
            <Card.Text>
              Tag: {originalTag}
            </Card.Text>
          }
          <Row>
            <Col xs={3}>
              <Button
                variant="warning"
                style={buttonStyle}
                className="me-2"
                onClick={() => setShowEditRfidModal(true)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Col>
            <Col xs={3}>
              <Button variant="danger" style={buttonStyle} onClick={onDeleteClick}>
                <FontAwesomeIcon icon={deleteIcon === "trash" ? faTrashAlt : faXmark} />
              </Button>
            </Col>
            <Col xs={3}>
              <Button variant="info" style={buttonStyle} onClick={() => setShowEditTagModal(true)}>
                <FontAwesomeIcon icon={faTag} />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Editing the RFID */}
      <Modal show={showEditRfidModal} onHide={() => setShowEditRfidModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit RFID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="rfid-input" className="form-label">
            RFID:
          </label>
          <input
            type="text"
            id="rfid-input"
            className="form-control mb-2"
            value={rfid}
            onChange={(e) => setRfid(e.target.value)}
          />
          <QrScanner
            onResult={(res) => {
              if (!!res) {
                setRfid(res.text)
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditRfidModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditRFIDSaveChanges}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Editing the tag */}
      <Modal show={showEditTagModal} onHide={() => setShowEditTagModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="tag-input" className="form-label">
            Tag:
          </label>
          <input
            type="text"
            id="tag-input"
            className="form-control"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditTagModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditTagSaveChanges}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default ClothingItem;
