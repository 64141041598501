import * as tmImage from '@teachablemachine/image';
import { useEffect, useRef, useState } from "react";

import { Modal, OverlayTrigger, Toast, ToastContainer, Tooltip, Row, Col, Button, Container, InputGroup, Dropdown, Spinner } from "react-bootstrap";

import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import {
  addClothing,
  getLocalCurrentResidency,
  getResidencies,
  getTextColor,
} from "../services/service";

import Category from "../util/Category";
import SubCategory from "../util/SubCategory";
import QrScanner from '../components/QrScanner';

function AddClothes() {
  const [overlayIdx, setOverlayIdx] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [image, setImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [rfid, setRfid] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [overlays, setOverlays] = useState([]);
  const [residencies, setResidencies] = useState([]);
  const [currentResidency, setCurrentResidency] = useState([]);
  const [isTeachableMachineLoaded, setIsTeachableMachineLoaded] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [currentPrediction, setCurrentPrediction] = useState("");
  const [loopIntervalId, setLoopIntervalId] = useState("");
  const [variant, setVariant] = useState("primary");
  const manualOverlayChosenRef = useRef(false);

  // Dynamically added via useEffect
  // Map the index of the overlay to that overlay's category.
  const [idxToCat, setIdxToCat] = useState({});

  const TM_URL = "https://teachablemachine.withgoogle.com/models/di7HE36Th/";

  let model, webcam;

  // Create a loop interval that runs every second.
  // This loop makes predictions of the subclass of the clothing
  // item in frame every second.
  // Method is useful when the user takes a picture and no further
  // prediction needs to be made.
  const makeLoopInterval = () => {
    removeLoopInterval();
    const intervalId = setInterval(() => {
      if (!manualOverlayChosenRef.current) {
        loop();
      }
    }, 1000);

    setLoopIntervalId(intervalId);
  }

  // Remove the loop interval of predictions.
  const removeLoopInterval = () => {
    if (loopIntervalId) {
      clearInterval(loopIntervalId);
    }
  }

  // As long as the user has not entered an RFID, flash the set RFID button.
  useEffect(() => {
    let intervalId = null;
    if (rfid === "") {
      intervalId = setInterval(() => {
        setVariant(variant === "primary" ? "warning" : "primary");
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [rfid, variant]);

  useEffect(() => {
    // make only predictions when the image is not loaded (= no picture taken yet)
    if (!image && predictions.length > 0) {
      const prediction = predictions[0];
      // prediction is an array of objects with keys: className, probability.
      // Find the highest probability one.
      let idx = 0;
      let highestProbability = 0;
      for (let i = 0; i < prediction.length; i++) {
        if (prediction[i].probability > highestProbability) {
          highestProbability = prediction[i].probability;
          idx = i;
        }
      }
      // Subcategory -> Category
      // get the subcategory and then get the category based on the subcategory
      const subCategory = prediction[idx].className;
      const category = SubCategory[subCategory];

      // Get the key of the category since we're using enums
      const categoryKey = getKeyByValue(Category, category);

      // Get the index of overlay of the highest probability class via catToIdx
      const categoryIdx = getKeyByValue(idxToCat, categoryKey);

      // Use the idx to set the overlay
      setOverlayIdx(categoryIdx);
      setCurrentPrediction(subCategory);
    }
  }, [predictions]);


  // This useEffect hook ensures that the Teachable Machine model is loaded and initialized when the component mounts.
  useEffect(() => {
    if (!isTeachableMachineLoaded) {
      setIsTeachableMachineLoaded(() => {
        init();
        return true;
      });
    }
  }, []);

  // Load the image model and setup the webcam
  // Default Teachable Machine code from when you export your model.
  // Source: https://github.com/googlecreativelab/teachablemachine-community/blob/master/snippets/markdown/image/tensorflowjs/javascript.md
  async function init() {
    const modelURL = TM_URL + "model.json";
    const metadataURL = TM_URL + "metadata.json";

    // load the model and metadata
    model = await tmImage.load(modelURL, metadataURL);

    // Convenience function to setup a webcam
    const flip = true; // whether to flip the webcam
    // Use a high resolution webcam to get a better image for prediction
    webcam = new tmImage.Webcam(1200, 1800, flip); // width, height, flip
    await webcam.setup(); // request access to the webcam
    await webcam.play();
    makeLoopInterval();

    // append elements to the DOM
    document.getElementById("webcam-container").appendChild(webcam.canvas);
  }

  async function loop() {
    // If the model isn't loaded in yet or there is an image; return
    if (!model || image) return;

    webcam.update(); // update the webcam frame
    await predict();
  }

  // run the webcam image through the image model
  async function predict() {
    // If an image is already taken or the loop interval is not set; return
    if (image) return;

    // If the camera hasn't initialized yet; return 
    if (!webcam.canvas) return;

    // predict can take in an image, video or canvas html element
    const prediction = await model.predict(webcam.canvas);
    setPredictions((prevState) => {
      // Clear out the previous array
      const emptyArray = prevState.splice();

      // Add the new predictions
      emptyArray.push(prediction);

      return emptyArray;
    });
  }

  const selectResidency = (residency) => {
    setCurrentResidency(residency);
  };

  // On manual overlay change, set the current subcategory to that of 
  // the first subcategory of the current selected category of the overlay.
  const setCurrentPredictionToFirstInSubCategory = (updatedOverlayIdx) => {
    const currentCat = Category[idxToCat[updatedOverlayIdx]];
    // Get the first subcategory of the current category
    const subCategory = Object.keys(SubCategory).find(key => SubCategory[key] === currentCat);
    setCurrentPrediction(subCategory);
  }

  // Cycle through overlays
  const handleNextClick = () => {
    manualOverlayChosenRef.current = true;
    setOverlayIdx((prev) => {
      const nextIdx = (prev + 1) % overlays.length
      setCurrentPredictionToFirstInSubCategory(nextIdx);
      return nextIdx
    });
  };
  const handlePrevClick = () => {
    manualOverlayChosenRef.current = true;
    setOverlayIdx((prev) => {
      const prevIdx = (prev === 0 ? overlays.length - 1 : prev - 1)
      setCurrentPredictionToFirstInSubCategory(prevIdx);
      return prevIdx
    });
  };

  // Image captured
  const handlePhoto = (uri) => {
    clearInterval(loopIntervalId); // No more predictions
    const canvas = document.createElement('canvas')
    const img = new Image()
    img.src = uri
    img.onload = () => {
      const ctx = canvas.getContext('2d')
      // Make the image square
      const aspectRatio = 1
      const w = img.width > img.height ? img.height * aspectRatio : img.width
      const h = img.height > img.width ? img.width * aspectRatio : img.height
      canvas.width = w
      canvas.height = h

      ctx.drawImage(img, 0, 0, w, h)
      const squareImage = canvas.toDataURL()

      setImage(squareImage)
    }
  };

  // Remove captured image
  const resetImage = () => {
    setImage();
  };

  // Reset the image and overlay
  const resetForNextImage = () => {
    manualOverlayChosenRef.current = false;
    resetImage();
    setShowToast(true);
    setRfid("");
  }

  // Save image to Firebase
  const saveImage = async () => {
    const category = Category[currentCategory()];
    const clothingPiece = {
      cat: category,
      subcat: currentPrediction,
      rfid: rfid,
      img: image,
    };

    await addClothing(clothingPiece, currentResidency);
    resetForNextImage();
  };

  // Toggle overlays whenever the image state changes
  useEffect(() => {
    setShowOverlay(!showOverlay);

    // Only when the image is reset, make the loop interval again
    if (!image) {
      makeLoopInterval();
    }
    // eslint-disable-next-line
  }, [image]);

  // Fetch the current residency
  useEffect(() => {
    getResidencies().then((residencies) => {
      setResidencies(residencies);
      const localCurrentResidency = getLocalCurrentResidency();
      if (!localCurrentResidency) {
        selectResidency(residencies[0]);
      } else {
        selectResidency(localCurrentResidency);
      }
    });

    // Import ALL overlays from the overlays folder through context
    const importAll = (r) => {
      r.keys().forEach((r, idx) => {
        let overlayName = r.replace(/^.*[\\/]/, '');
        // Also remove the .* from the name
        overlayName = overlayName.replace(/\..*/, '');
        const key = getKeyByValue(Category, overlayName);
        idxToCat[idx] = key;
      });

      return r.keys().map(r);
    };

    const overlayContext = importAll(
      require.context("../../src/overlays", false, /\.(png|jpe?g|svg|JPE?G)$/)
    );

    setOverlays(overlayContext);
  }, []);

  // Efficiently get the key of value in an object
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  // RFID saved
  const handleSaveChange = () => {
    setShowModal(false);
  };

  const buttonStyle = {
    minWidth: '8rem'
  }

  const allSubCategoriesOfCategory = () => {
    const cCat = Category[currentCategory()];

    // Get all keys where the values are equal to cCat
    const keys = Object.keys(SubCategory).filter(key => SubCategory[key] === cCat);
    return keys;
  }

  // Get the current category based on the overlay index
  const currentCategory = () => {
    return idxToCat[overlayIdx];
  }

  // Show a tooltip as an extra measure for the user to know that they need to add an RFID tag
  const disabledSaveTip = <Tooltip id="saveDisabled">Add an RFID tag first!</Tooltip>
  const predictionStyling = (shouldShowOnPrediction) => {
    if (shouldShowOnPrediction) return { display: currentPrediction ? '' : 'none' };
    return { display: currentPrediction ? 'none' : '' };
  }

  return (
    <Container>
      {/* Hidden camera for Teachable Machine to use */}
      <Row style={{ display: 'none' }}>
        <div id="webcam-container"></div>
      </Row>

      {/* Toast that shows when an image is saved */}
      <Row>
        <ToastContainer className="p-3" position="bottom-end">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            bg="info"
          >
            <Toast.Body>Image saved!</Toast.Body>
          </Toast>
        </ToastContainer>
      </Row>

      {/* Header */}
      <Row className="justify-content-center">
        <h1>Add clothes</h1>
      </Row>

      {/* Residency dropdown */}
      <Row>
        <Col className='d-flex justify-content-center' >
          <div className="dropdown w-50" >
            <button
              style={{
                backgroundColor: currentResidency.color,
                color: getTextColor(currentResidency.color || "grey"),
                width: "100%",
                height: "100%"
              }}
              className="btn btn-secondary dropdown-toggle btn-sm"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {currentResidency.tag ? currentResidency.tag : "Select a Residency"}
            </button>
            <ul className="dropdown-menu">
              {residencies.map((residency) => (
                <li key={residency.id}>
                  <button
                    style={{
                      backgroundColor: residency.color,
                      color: getTextColor(residency.color),
                    }}
                    className="dropdown-item"
                    onClick={() => selectResidency(residency)}
                  >
                    {residency.tag}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>

      {/* Loading indicator for camera */}
      <Row style={predictionStyling(false)} className="justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Starting camera</span>
        </Spinner>
        Starting camera...
      </Row>

      {/* Camera + Overlays */}
      <Row style={predictionStyling(true)} >
        <div
          className="d-flex justify-content-center my-3"
          style={{ position: "relative" }}
        >
          {image ? (
            <img id="img-src" alt="" src={image} />
          ) : (
            <Camera
              onCameraStart={() => setShowOverlay(true)}
              onCameraError={() => setShowOverlay(false)}
              imageType="png"
              onTakePhoto={handlePhoto}
              idealFacingMode="environment"
              isImageMirror={false}
            />
          )}
          {showOverlay && (
            <img
              src={overlays[overlayIdx]}
              alt="overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>

        {/* Popup that appears after capturing an image */}
        <div className="overlay-container">
          {image ? (
            <>
              {rfid === "" || showModal ? (
                <></>
              ) : (
                <div className="d-flex justify-content-center mb-3">
                  RFID: {rfid}
                </div>
              )}
              {currentCategory() === "" || showModal ? (
                <></>
              ) : (
                <div className="d-flex justify-content-center mb-3">
                  Category: {currentCategory()}
                </div>
              )}
              <div className="d-flex justify-content-center gap-3">
                <Button variant="danger" onClick={resetImage}>
                  Clear
                </Button>
                {rfid === "" ? (
                  <div>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={disabledSaveTip}
                      show={rfid === ''}
                      trigger='click'
                    >
                      <Button variant={variant} onClick={() => setShowModal(true)}>
                        Add scan tag
                      </Button>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <Button variant="primary" onClick={() => setShowModal(true)}>
                    Edit scan tag
                  </Button>
                )}
                <InputGroup>
                  <InputGroup.Text id="subcategory">Subcat.</InputGroup.Text>
                  <Dropdown>
                    <Dropdown.Toggle
                      aria-label="Subcategory"
                      aria-describedby="subcategory" id="dropdown-basic">
                      {currentPrediction}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {allSubCategoriesOfCategory().map((subcat) => (
                        <Dropdown.Item
                          key={subcat}
                          onClick={() => setCurrentPrediction(subcat)}
                        >
                          {subcat}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>
                <Button disabled={rfid === ""} variant="success" onClick={saveImage}>
                  Save
                </Button>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center gap-3">
              <Button style={buttonStyle} onClick={handlePrevClick}>Previous</Button>
              <Button style={buttonStyle} onClick={handleNextClick}>Next</Button>
            </div>
          )}
        </div>
      </Row>

      {/* Modal for editing the RFID */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add RFID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="rfid-input" className="form-label">
            RFID:
          </label>
          <input
            type="text"
            id="rfid-input"
            className="form-control mb-2"
            value={rfid}
            onChange={(e) => setRfid(e.target.value)}
          />

          <QrScanner
            onResult={(res) => {
              if (!!res) {
                setRfid(res.text)
              }
            }}
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChange}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container >
  );
}

export default AddClothes;
